import {
  mapState,
  mapMutations,
  mapActions,
  mapGetters,
} from 'vuex';
import {
  mdiPrinter,
} from '@mdi/js';
import {
  amountFormat,
  amountToNumber,
  getItemsIndexByID,
  focusAmount,
} from '../../util/shared/vue-global';
import {
  isNotBeforeDate,
  defaultDurationOfCorrection,
} from '../../util/shared/tmc-global';
import { fields } from './DepositVoucher.shared';

const sumReduce = (acc, val) => acc + (amountToNumber(val.amount) || 0);
const reconcillationMapper = [
  { depositType: 'CASHCHECK', collectionName: 'cashCheckItems' },
  { depositType: 'CREDIT', collectionName: 'creditItems' },
  { depositType: 'TRUST', collectionName: 'trustItems' },
  { depositType: 'EFT', collectionName: 'EFTItems' },
];
export default {
  data: () => ({
    icons: {
      mdiPrinter,
    },
    registrationOverride: false,
  }),
  created() {
    if (this.internalValue.type === 'DEPOSIT'
      && this.unregisteredDep && this.isNewItem) {
      this.internalValue.type = 'UNREGISTERED';
    }
    this.$store.dispatch('SystemConfig/loadConfig', { requiredScope: 'odconfig' });
  },
  computed: {
    ...mapState({
      departments: (state) => state.OD.departments,
      transTypes: (state) => state.enums.ODTransType,
      statuses: (state) => state.enums.ODStatus,
      nextVoucherNumber: (state) => state.OD.nextVoucherNumber,
      nextRegistrationNumber: (state) => state.OD.nextRegistrationNumber,
      deptBalanceBefore: (state) => state.OD.deptBalanceBefore,
      newEditIndex: (state) => state.OD.newEditIndex,
      voucherRegPolling: (state) => state.OD.voucherRegPolling,
      voucherRegPulse: (state) => state.OD.voucherRegPulse,
      odSettingItem: (state) => state.SystemConfig.odSettingItem,
      transactions: (state) => state.OD.transactions,
      autoFocus: (state) => state.OD.autoFocus,
      stateDialog: (state) => state.OD.newEditDialog,
    }),
    ...mapGetters([
      'todaysDate',
    ]),
    isTransactionTypeReadOnly() {
      // if (this.isNewItem) {
      //   return false;
      // }
      // For now we are making type readonly for new as well as edit dialog box
      return true;
    },
    voucherRegPulseClass() {
      return this.voucherRegPulse ? 'color-anim-pulse' : 'color-anim-black';
    },
    unregisteredDep() {
      return this && this.odSettingItem && this.odSettingItem.unregisteredDep;
    },
    durationOfCorrection() {
      if (this && this.odSettingItem && this.odSettingItem.durationOfCorrection) {
        return parseInt(this.odSettingItem.durationOfCorrection, 10)
          || defaultDurationOfCorrection;
      }
      return defaultDurationOfCorrection;
    },
    filteredTransTypes() {
      if (!this.transTypes) {
        return [];
      }
      return this.transTypes.filter((t) => (
        (t.name !== 'UNREGISTERED' || this.unregisteredDep)
        && (t.name !== 'DEPOSIT' || !this.isNewItem || !this.unregisteredDep)
      ));
    },
    reqdSelectValidation() {
      return [
        (value) => !!value || 'Required',
      ];
    },
    departmentLabel() {
      const balance = (this.deptBalanceBefore !== undefined)
        ? amountFormat(this.deptBalanceBefore) : '...';
      // console.log(`stateBal: ${this.deptBalanceBefore}, amount: ${amount}, balance: ${balance}`);
      return `Department (bal before: $${balance})`;
    },
    unregisteredOrDeposit() {
      return this.internalValue.type === 'DEPOSIT'
        || this.internalValue.type === 'UNREGISTERED';
    },
    isTrustReceipt() {
      return this.unregisteredOrDeposit && this.internalValue.depositType === 'TRUST';
    },
    voidVoucher() {
      return this.internalValue.type === 'VOUCHER'
        && this.internalValue.status === 'VOID';
    },
    isBegBal() {
      return this.internalValue.type === 'BEGINNINGBALANCE';
    },
    shouldValidateItemizedAmount() {
      return this.unregisteredOrDeposit && this.isDepositItemsRequiredValidation;
    },
    registerDateRules() {
      return [
        (value) => {
          const isNotBefore = isNotBeforeDate(
            value, this.todaysDate, (this.durationOfCorrection + 1),
          );
          return isNotBefore || 'You are not allowed to change or enter transactions that'
            + ` are more than ${this.durationOfCorrection} business days old. Please use a correction`
            + ' transaction type for today\'s date instead.';
        },
      ];
    },
    fiscalYearValidation() {
      return [
        (value) => !!value || 'Required',
        (value) => !Number.isNaN(parseFloat(value)) || 'Numeric required',
        (value) => parseFloat(value) > 0 || 'Must be a positive year',
        (value) => parseFloat(value) < 3000 || 'Must be a reasonable year',
      ];
    },
    amountValidation() {
      return [
        (value) => {
          // type number with value 0 is 'falsy'
          // interpret it as 'truthy'
          if (amountToNumber(value) === 0) {
            return true;
          }
          return !!value || 'Required';
        },
        (value) => {
          if (!this.voidVoucher) {
            return true;
          }
          return amountToNumber(value) === 0 || 'Voided vouchers must have zero amounts.';
        },
        (value) => {
          if (!this.unregisteredOrDeposit) {
            return true;
          }
          if (!this.shouldValidateItemizedAmount) {
            return true;
          }
          const totalAsNumber = this.totalReconcillationAmount();
          return amountToNumber(value) === totalAsNumber || 'Mismatched Reconciliation Amount';
        },
        (value) => {
          if (this.unregisteredOrDeposit) {
            return amountToNumber(value) >= 0 || 'You are not allowed to enter negative deposits.'
              + ' Please use a correction transaction type.';
          }
          return true;
        },
      ];
    },
    isNewItem() {
      return this.newEditIndex === -1;
    },
    shouldSaveTrans() {
      const saveTrans = isNotBeforeDate(
        this.internalValue.register,
        this.todaysDate,
        (this.durationOfCorrection + 1),
      );
      return this.isNewItem || saveTrans;
    },
    isTooltipVisible() {
      return this.odSettingItem && this.odSettingItem.registrationNumber;
    },
    registrationHoverCaption() {
      if (this.isNewItem) {
        return 'Record must be saved before its registration number can be changed!';
      }
      return 'Double-click to override (use ⚠️ caution)!';
    },
    isDepositItemsRequiredValidation() {
      let requiredValidation = false;
      reconcillationMapper.forEach((collection) => {
        if (this.internalValue.depositType === collection.depositType
          && (this.internalValue[collection.collectionName] || []).length > 0
        ) {
          requiredValidation = true;
        }
      });
      return requiredValidation;
    },
  },
  methods: {
    ...mapActions('OD', [
      'loadFiscalYears',
    ]),
    ...mapMutations('OD', [
      'setNewEditItem',
      'setNewEditDialog',
      'setNewEditIndex',
      'setVoucherRegPolling',
      'setDefaultStats',
      'setPreWhatStr',
      'setPreWhomStr',
      'setAutoFocus',
    ]),
    amountFormat,
    getItemsIndexByID,
    focusAmount,
    registerDateBlurred(value) {
      const isNotBeforeTwoPrior = isNotBeforeDate(
        value, this.todaysDate, (this.durationOfCorrection + 1),
      );
      const valueDt = new Date(value);
      const todayDt = new Date(this.todaysDate);
      const diffMs = todayDt - valueDt;
      if (isNotBeforeTwoPrior && diffMs > 0) {
        this.$store.dispatch('flashInfo', `Please remember to export data for the GL for ${value}
        as this change will affect GL balances for ${value}.`);
      }
    },
    updateDeptAndNextTransNum() {
      this.updateDeptBalance();
      this.determineNextTransactionNumbers();
    },
    updateDeptBalance() {
      this.$store.dispatch('OD/loadDeptBalance', this.editedItem);
      if (this.editedItem.type === 'VOUCHER') {
        this.determineNextTransactionNumbers(true);
        this.$store.dispatch('OD/loadWhoms', {
          fiscalYear: this.editedItem.fiscalYear,
        });
        this.$store.dispatch('OD/loadWhats', {
          fiscalYear: this.editedItem.fiscalYear,
        });
      }
    },
    determineNextTransactionNumbers(isRequired = false) {
      if (this.isNewItem || isRequired) {
        const loadStatsPromise = this.$store.dispatch(
          'OD/loadTransStats',
          {
            type: this.editedItem.type,
            department: this.editedItem.department,
            fiscalYear: this.editedItem.fiscalYear,
          },
        );
        if (this.isNewItem) {
          loadStatsPromise.then(() => {
            this.internalValue.voucher = this.nextVoucherNumber;
            this.internalValue.registration = this.nextRegistrationNumber;
          });
        }
      }
    },
    defaultItem() {
      const defaultObj = { ...fields() };
      return defaultObj;
    },
    close() {
      this.setDefaultStats();
      this.setNewEditDialog(false);
      this.setVoucherRegPolling(undefined);
      this.$nextTick(() => {
        this.setNewEditItem(this.defaultItem());
        this.setNewEditIndex(-1);
      });
    },
    validateForm() {
      const result = this.$refs.form.validate();
      if (!result && global.isVueUnitTest) {
        const errorClassLists = this.$refs.form.inputs
          .filter((i) => !i.validate(true))
          .map((i) => i.$el.classList)
          .map((cl) => JSON.stringify(cl));
        console.warn(JSON.stringify(errorClassLists, null, 1));
      }
      return result;
    },
    saveableEdits() {
      this.editedItem.amount = amountToNumber(this.editedItem.amount);
      // validation-ish concerns (parsing string input back to numbers, etc)
      if (this.editedItem.finalized === '' || this.editedItem.status === 'OUTSTANDING') {
        this.editedItem.finalized = null;
      }
      // don't store unrelated details
      if (this.editedItem.depositType) {
        const collectionToUpdate = reconcillationMapper
          .filter((c) => c.depositType !== this.editedItem.depositType);
        this.cleanupUnrelatedDetails(collectionToUpdate);
        this.updateDetailsForReq();
      } else {
        this.editedItem.depositType = undefined;
        this.cleanupUnrelatedDetails(reconcillationMapper);
      }
      // ------------------
      // misc warnings
      // ------------------
      let shouldValidateForm = true;

      if (this.editedItem.type === 'VOUCHER') {
        let newBalance = (this.deptBalanceBefore || 0.0) - amountToNumber(this.editedItem.amount);
        if (!this.isNewItem) {
          const trans = this.transactions.find((t) => t.id === this.editedItem.id);
          if (trans) {
            newBalance += amountToNumber(trans.amount);
          }
        }
        if (newBalance < 0) {
          if (!window.confirm('Warning: This voucher will result in a negative balance'
            + ' for [dept desc]. Do you wish to proceed?')) {
            shouldValidateForm = false;
          }
        }
      }
      return shouldValidateForm ? this.validateForm() : false;
    },
    cleanupUnrelatedDetails(collectionToUpdate) {
      collectionToUpdate.forEach((collection) => {
        this.editedItem[collection.collectionName] = [];
      });
    },
    updateDetailsForReq() {
      const collection = reconcillationMapper
        .find((m) => m.depositType === this.editedItem.depositType);
      if (collection) {
        this.editedItem[collection.collectionName] = (this.editedItem[collection.collectionName]
            || []).map((item) => {
          const amount = amountToNumber(item.amount);
          return {
            ...item,
            amount,
          };
        });
      }
    },
    async imagesLeftClick(leftClick) {
      if (this.shouldSaveTrans) {
        const didSave = await this.internalSave(undefined, false);
        if (didSave) {
          this.$nextTick(() => {
            leftClick();
          });
        }
      } else {
        leftClick();
      }
    },
    async imagesRightClick(rightClick) {
      if (this.shouldSaveTrans) {
        const didSave = await this.internalSave(undefined, false);
        if (didSave) {
          rightClick();
        }
      } else {
        rightClick();
      }
    },
    async internalSave(e, autoClose) {
      const valResults = this.saveableEdits();
      let upsResult = false;
      // console.log(valResults);
      if (valResults) {
        // keyboard event shift + enter
        if (e && e.shiftKey) {
          upsResult = await this.$store.dispatch('OD/upsTransaction', {
            transaction: this.editedItem,
          });

          if (upsResult) {
            this.$store.dispatch('flashSuccess', 'Successfully saved transaction');
            // Reset items ID for next.
            this.editedItem.id = '';
            this.determineNextTransactionNumbers();
            this.$refs.amount.focus();
          } else {
            this.$store.dispatch('flashError', 'Failed to save transaction');
          }
        } else {
          // Stop poling when going for saving transaction
          this.setNewEditIndex(-2);
          // upsert it
          upsResult = await this.$store.dispatch('OD/upsTransaction', {
            transaction: this.editedItem,
          });
          this.setNewEditIndex(this.getItemsIndexByID(this.transactions, this.editedItem));
          if (autoClose && upsResult) {
            this.close();
          }
        }
        this.loadFiscalYears();
      }
      return upsResult;
    },
    saveToStore() {
      if (this.isNewItem && this.editedItem.type === 'VOUCHER') {
        this.setPreWhatStr(this.editedItem.whatStr);
        this.setPreWhomStr(this.editedItem.whomStr);
      }
      if (this.isNewItem && this.editedItem.type === 'DEPOSIT') {
        localStorage.setItem('odtrans.usedLastRegister', this.editedItem.register);
      }
    },
    async save(e) {
      this.saveToStore();
      return this.internalSave(e, true);
    },
    roundAmount() {
      this.internalValue.amount = amountFormat(amountToNumber(this.internalValue.amount));
    },
    amountToNumber() {
      this.internalValue.amount = amountToNumber(this.internalValue.amount);
    },
    overrideRegistrationNumber() {
      if (this.isTooltipVisible) {
        this.registrationOverride = true;
      }
    },
    totalReconcillationAmount() {
      const nameOfCollection = (reconcillationMapper
        .find((m) => m.depositType === this.internalValue.depositType) || {}).collectionName;
      const items = this.internalValue[nameOfCollection] || [];
      const total = items.reduce(sumReduce, 0);
      return amountToNumber(total);
    },
  },
};
