import {
  toFiscalYear,
} from '../../util/shared/tmc-global';
import { getWorkstationNumber } from '../../util/workstation-util';

export const fields = () => ({
  id: '',
  type: '',
  depositType: '',
  registration: 0,
  voucher: 0,
  voucherAddress: {
    address1: undefined,
    address2: undefined,
    city: undefined,
    state: undefined,
    zip: undefined,
  },
  register: '',
  fiscalYear: toFiscalYear(new Date()), // Todo - update for new fiscal year
  department: undefined,
  whatStr: undefined,
  whomStr: undefined,

  finalized: '',
  status: undefined,
  remarks: '',
  amount: 0.0,
  cashCheckItems: [],
  creditItems: [],
  trustItems: [],
  wkst: getWorkstationNumber(),
});

export default {};
