<template>
  <v-card class="VoucherDetails"
    @keydown.enter="save"
  >
    <v-card-title>
      <span v-if="isNewItem" class="subtitle-1">New Item</span>
      <span v-if="!isNewItem" class="subtitle-1">Edit Item</span>
    </v-card-title>
    <v-card-text>
      <v-form ref="form">
        <v-container class="grey lighten-5">
          <v-row>
            <v-col cols="2">
              <v-text-field class="odtrans fiscalYear"
                id="odTransFYText"
                v-model.number="internalValue.fiscalYear"
                label="Fiscal Year"
                type="number"
                @input="voucherFYChange"
                :rules="fiscalYearValidation"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-select class="odtrans type"
                id="odTransTypeSelect"
                :readonly="isTransactionTypeReadOnly"
                v-model="internalValue.type"
                :items="filteredTransTypes"
                :rules="reqdSelectValidation"
                @change="determineNextTransactionNumbers()"
                item-text="description"
                item-value="name"
                label="Type"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-tooltip bottom
                :open-on-hover="isTooltipVisible">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field class="odtrans registration"
                    id="odTransRegistrationText"
                    v-bind="attrs"
                    v-on="on"
                    v-model.number="internalValue.registration"
                    label="Registration #"
                    color="red"
                    type="number"
                    :readonly="!registrationOverride || isNewItem"
                    :class="voucherRegPulseClass"
                    @dblclick.stop="overrideRegistrationNumber"
                  ></v-text-field>
                </template>
                <span>{{registrationHoverCaption}}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="4">
              <ClarionDateControl class="odtrans register"
                id="odTransRegisterDate"
                v-model="internalValue.register"
                :isRequired="true"
                :isIsoDate="true"
                :label="`Register Date`"
                :rules="registerDateRules"
                @blurred="registerDateBlurred"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" sm="6" md="5">
              <v-select class="odtrans department"
                id="odTransDepartmentSelect"
                :autofocus="autoFocus.isDepartment"
                v-model="internalValue.department"
                :items="departments"
                :rules="reqdSelectValidation"
                item-text="caption"
                item-value="id"
                :label="departmentLabel"
                @change="updateDeptBalance"
              ></v-select>
            </v-col>
            <v-col cols="3">
              <VoucherNumberInput class="odtrans voucher"
                id="odTransVoucherInput"
                v-model.number="internalValue.voucher"
                :department="internalValue.department"
                :fiscalYear="internalValue.fiscalYear"
                :isNewItem="isNewItem"
              />
            </v-col>
            <v-col cols="4">
              <v-autocomplete class="odtrans whom"
                id="odTransToWhom"
                :autofocus="autoFocus.isToWhom"
                v-model="internalValue.whomStr"
                :items="whoms"
                :rules="reqdSelectValidation"
                label="To Whom"
                @keydown="whomShortcutHandler"
                @update:list-index="whomUpdateSearchInputByNumber"
                @update:search-input="whomUpdateSearchInput"
                @focus="onFocusAutocomplete"
              >
                </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-autocomplete class="odtrans what"
                id="odTransForWhat"
                ref="autocompleteForWhat"
                v-model="internalValue.whatStr"
                :items="whats"
                label="For What"
                @keydown="whatShortcutHandler"
                @update:list-index="whatUpdateSearchInputByNumber"
                @update:search-input="whatUpdateSearchInput"
              >
                </v-autocomplete>
            </v-col>
            <v-col cols="3" sm="4" md="2">
              <v-text-field class="odtrans amount"
                id="odTransAmountText"
                :autofocus="autoFocus.isAmount"
                v-model="internalValue.amount"
                ref="amount"
                :rules="amountValidation"
                label="Amount"
                @blur="roundAmount"
                @focus="focusAmount($event); amountToNumber()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" sm="5" md="3">
              <v-select class="odtrans status"
                id="odTransStatusSelect"
                v-model="internalValue.status"
                :items="statuses"
                :rules="reqdSelectValidation"
                item-text="description"
                item-value="name"
                label="Status"
              ></v-select>
            </v-col>
            <v-col cols="3" sm="5" md="3">
              <ClarionDateControl class="odtrans finalized"
                id="odTransFinalPaidDate"
                v-model="internalValue.finalized"
                :isRequired="false"
                :isIsoDate="true"
                :label="`Date Pd/Can`"
              />
            </v-col>
            <v-col>
              <v-text-field class="odtrans remarks"
                id="odTransRemarksText"
                v-model="internalValue.remarks"
                label="Remarks"
                type="string"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <Address class="odtrans voucherAddress"
              id= "odTransPayVoucherAddress"
              :address="internalValue.voucherAddress"
              @update:address="updateVoucherAddress"
              caption="Pay to the order of:" />
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn class="odtrans print"
        id="odTransPrint"
        text color="blue darken-1"
        @click="print"
      >
        <v-icon>{{icons.mdiPrinter}}</v-icon>
        Print
      </v-btn>
      <v-spacer></v-spacer>
      <AuditDialog id="odTransAuditDialog"
        class="od-trans-audit-dialog"
        :identifier="internalValue.id"
        activatorCaption="Audit History"
      />
      <UploadFile
        class="odtrans uploadFile"
        bucketName="OD"
        program="OD"
        parentObjectType="ODTransaction"
        :parentObjectId="internalValue.id"
        :itemIsSaved="!isNewItem">
        <template v-slot:activator="{ activatorProps }">
          <v-btn
            color="primary"
            text
            @contextmenu.prevent="imagesRightClick(activatorProps.rightClick)"
            @click="imagesLeftClick(activatorProps.leftClick)"
          >
            {{activatorProps.caption}}
          </v-btn>
        </template>
      </UploadFile>
      <v-btn class="odtrans close"
        id="odTransClose"
        text color="blue darken-1"
        @click="close"
      >
        Cancel
      </v-btn>
      <v-btn class="odtrans save"
        id="odTransSave"
        text color="blue darken-1"
        @click="save"
      >
        Save
      </v-btn>
      <div v-if="isNewItem">(Shift+Enter to Duplicate)</div>
    </v-card-actions>
  </v-card>
</template>
<script>
import {
  mapActions,
  mapGetters,
} from 'vuex';
import DepositVoucherMixin from './DepositVoucher.mixin';
import VoucherNumberInput from './VoucherNumberInput.vue';
import ClarionDateControl from '../common/ClarionDateControl.vue';
import Address from '../common/Address.vue';
import UploadFile from '../common/UploadFile.vue';
import AuditDialog from '../common/audit/AuditDialog.vue';
import { clients } from '../../util/clients';
import {
  isNotBeforeDate,
} from '../../util/shared/tmc-global';
import {
  onFocusAutocomplete,
  focusNextField,
} from '../../util/shared/vue-global';

const { backendRest } = clients.direct;

export default {
  name: 'VoucherDetails',
  mixins: [DepositVoucherMixin],
  data: () => ({
    whatLastSearched: undefined,
    whomLastSearched: undefined,
  }),
  components: {
    VoucherNumberInput,
    ClarionDateControl,
    UploadFile,
    AuditDialog,
    Address,
  },
  props: {
    editedItem: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    internalValue: {
      get() {
        return this.editedItem;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    whats() {
      const whatList = (this.$store.state.OD.whats || []).map((w) => w.description || '');
      whatList.push(this.editedItem.whatStr || '');
      return whatList;
    },
    whoms() {
      const whomList = (this.$store.state.OD.whoms || []).map((w) => w.description || '');
      whomList.push(this.editedItem.whomStr || '');
      return whomList;
    },
    ...mapGetters('user', [
      'getSettings',
    ]),
  },
  methods: {
    onFocusAutocomplete,
    focusNextField,
    ...mapActions('OD', [
      'upsWhat',
      'loadWhats',
      'upsWhom',
      'loadWhoms',
      'upsTransaction',
    ]),
    ...mapActions([
      'flashError',
      'flashInfo',
      'reAuth',
    ]),
    voucherFYChange() {
      // not super stable yet (TODO - research why)
      // this.whatShortcutHandler({ key: 'Tab' });
      // this.whomShortcutHandler({ key: 'Tab' });
      this.updateDeptBalance();
    },
    updateVoucherAddress(value) {
      const newInternalValue = {
        ...this.internalValue,
        voucherAddress: value,
      };
      this.setNewEditItem(newInternalValue);
    },
    async print() {
      const { jwt } = await this.reAuth();
      const { register } = this.internalValue;
      const saveTrans = isNotBeforeDate(
        register,
        this.todaysDate,
        (this.durationOfCorrection + 1),
      );
      let isReceiptPrintable;
      const printCounter = 1;
      if (saveTrans || this.isNewItem) {
        this.internalValue.hasPrinted = printCounter;
        // save print's count as well as edited data
        isReceiptPrintable = await this.internalSave(undefined, false);
      } else {
        const upsert = {
          transaction: {
            id: this.internalValue.id,
            hasPrinted: printCounter,
          },
        };
        // updating print count
        await this.upsTransaction(upsert);
        isReceiptPrintable = true;
      }
      if (isReceiptPrintable) {
        const { id } = this.internalValue;
        const url = `${backendRest.defaults.baseURL}/vouchercheck?odTransId=${id}&token=${jwt}`;
        window.open(url, '_blank');
      }
    },
    whatUpdateSearchInput(val) {
      this.whatLastSearched = val;
    },
    whatUpdateSearchInputByNumber(number) {
      this.whatLastSearched = this.whats[number];
    },
    async whatShortcutHandler(e) {
      if (e.key === 'Tab') {
        const { whatStr, fiscalYear } = this.internalValue;
        const { whatLastSearched } = this;
        const matchWhats = this.whats.filter((w) => w === whatStr);
        const matchWhatLastSearched = this.whats.filter((w) => w === whatLastSearched);
        if (matchWhats.length > 0 || whatLastSearched) {
          const matchWhat = matchWhats[0] ? matchWhats[0] : '';
          if (
            (matchWhat || '').toLowerCase() !== (whatLastSearched || '').toLowerCase()
            && (matchWhatLastSearched.length === 0)
          ) {
            this.flashInfo('Creating a new What entry.');
            // create a new record to match the description typed
            await this.upsWhat({
              _id: '',
              description: whatLastSearched,
              fiscalYear,
            });
            await this.loadWhats({
              fiscalYear,
            });
          }
          this.internalValue.whatStr = whatLastSearched;
        }
        focusNextField(this, e, 'amount');
      }
    },
    whomUpdateSearchInput(val) {
      this.whomLastSearched = val;
    },
    whomUpdateSearchInputByNumber(number) {
      this.whomLastSearched = this.whoms[number];
    },
    async whomShortcutHandler(e) {
      if (e.key === 'Tab') {
        const { whomStr, fiscalYear } = this.internalValue;
        const { whomLastSearched } = this;
        const matchWhoms = this.whoms.filter((w) => w === whomStr);
        const matchWhomLastSearched = this.whoms.filter((w) => w === whomLastSearched);
        if (matchWhoms.length > 0 || whomLastSearched) {
          const matchWhom = matchWhoms[0] ? matchWhoms[0] : '';
          if (
            (matchWhom || '').toLowerCase() !== (whomLastSearched || '').toLowerCase()
            && (matchWhomLastSearched.length === 0)
          ) {
            this.flashInfo('Creating a new Whom entry.');
            // create a new record to match the description typed
            await this.upsWhom({
              _id: '',
              description: whomLastSearched,
              fiscalYear,
            });
            await this.loadWhoms({
              fiscalYear,
            });
          }
          this.internalValue.whomStr = whomLastSearched;
        }
        focusNextField(this, e, 'autocompleteForWhat');
      }
    },
  },
};
</script>
