import store from '../../store';

export const getDefaultSumOfTrans = () => ({ isDisplay: false, totalAmt: 0.0, totalCount: 0 });

export const fetchODAmountTotal = async (chunkID) => {
  const params = {
    wildcard: chunkID,
    limit: 0,
  };
  const odSumOfTrans = getDefaultSumOfTrans();
  const odTransactions = await store.dispatch('OD/searchTransactions', params);
  const stats = { totalAmt: 0, count: 0 };
  (odTransactions || []).forEach((t) => {
    stats.totalAmt += t.amount;
    stats.count += 1;
  });
  odSumOfTrans.totalAmt = stats.totalAmt;
  odSumOfTrans.totalCount = stats.count;
  odSumOfTrans.isDisplay = true;
  return odSumOfTrans;
};
