<template>
  <v-data-table
    dense
    :headers="headers"
    :items="internalValue"
    :items-per-page="-1"
    hide-default-footer
    class="elevation-1 TrustDetails"
  >
    <template v-slot:top>
      <div class="d-flex justify-end">
        <v-btn
          class="mx-2" color="primary"
          fab icon x-small
          @click="addOneAndFocusIfFirst"
        >
          <v-icon>
            {{icons.mdiPlus}}
          </v-icon>
        </v-btn>
        <FortePayment
          v-if="isVisibleForteBtn"
          @forteResponse="forteResponse"
        />
      </div>
    </template>
    <template v-slot:[`item.taxId`]="{ item }">
      <v-text-field
        :ref="`item_${internalValue.indexOf(item)}`"
        dense
        v-model="item.taxId"
        single-line
        class="trust-tax-id"
        @blur="somethingChanged"
        @keydown="shortcutHandler($event, item)"
      ></v-text-field>
    </template>

    <template v-slot:[`item.taxYear`]="{ item }">
      <v-text-field
        dense
        v-model="item.taxYear"
        single-line
        @blur="somethingChanged"
        @keydown="shortcutHandler($event, item)"
      ></v-text-field>
    </template>

    <template v-slot:[`item.description`]="{ item }">
      <v-textarea
        dense
        v-model="item.description"
        single-line
        rows="1"
        @blur="somethingChanged"
        @keydown="shortcutHandler($event, item)"
      ></v-textarea>
    </template>

    <template v-slot:[`item.paymentType`]="{ item }">
      <v-select
        dense
        :items="paymentTypes"
        item-text="description"
        item-value="name"
        v-model="item.paymentType"
        single-line
        @blur="somethingChanged(); updateForteConfig()"
        @keydown="shortcutHandler($event, item)"
      ></v-select>
    </template>

    <template v-slot:[`item.amount`]="{ item, index }">
      <v-text-field
        dense
        v-model="item.amount"
        single-line
        class="trust-amount"
        @blur="somethingChanged(); sumAmount(); updateForteConfig()"
        @keydown="shortcutHandler($event, item, true, index)"
        @keydown.enter="somethingChanged"
        @focus="focusAmount"
      ></v-text-field>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small class="mr-2 od-trust-delete-icon"
        tabindex="-1"
        @click="removeOne(item)"
      >
        {{icons.mdiDelete}}
      </v-icon>
    </template>

    <template v-slot:[`item.paid`]="{ item }">
      <v-checkbox
        small class="mr-2 od-trust-ispaid-checkbox"
        v-model="item.isPaid"
        @change="somethingChanged"
      ></v-checkbox>
    </template>

    <template slot="body.append">
      <tr class="blue--text">
          <td colspan="2" id="total-trust-amount">
            Total : ${{ amountFormat(amountRemaining) }}
          </td>
      </tr>
    </template>

  </v-data-table>
</template>

<script>
import {
  mapState,
  mapActions,
} from 'vuex';

import {
  mdiPlus,
  mdiDelete,
} from '@mdi/js';

import {
  isTabOrPlus,
  focusAmount,
  amountFormat,
  amountToNumber,
  isAltC,
  isAltR,
  isAltE,
} from '../../util/shared/vue-global';
import FortePayment from '../common/FortePayment.vue';

const defaultItem = {
  taxId: '',
  taxYear: '',
  description: '',
  paymentType: null,
  amount: 0,
};

export default {
  name: 'TrustDetails',
  components: {
    FortePayment,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    depositAmount: [Number, String],
  },
  data: () => ({
    icons: {
      mdiPlus,
      mdiDelete,
    },
    headers: [
      { text: 'Tax ID', value: 'taxId' },
      { text: 'Tax Year', value: 'taxYear' },
      { text: 'Description', value: 'description' },
      { text: 'Payment Type', value: 'paymentType' },
      { text: 'Amount', value: 'amount' },
      { text: 'Actions', value: 'actions', sortable: false },
      { text: 'Paid', value: 'paid', sortable: false },
    ],
    amountSum: 0.00,
    renderComponentFlag: true,
  }),
  computed: {
    ...mapState({
      paymentTypes: (state) => state.enums.ODTrustPaymentTypeEnum || [],
      forteConfig: (state) => state.ForteService.forteConfig,
    }),
    internalValue: {
      get() {
        const newVal = JSON.parse(JSON.stringify(this.value));
        const updatedValue = newVal.map(this.loadInitialValues);
        return updatedValue;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    amountValidation() {
      return [
        (value) => !!value || 'Required',
        (value) => value !== 0 || 'Should be non-zero',
      ];
    },
    totalCreditAmount() {
      let totalSum = 0.00;
      (this.internalValue || []).forEach((item) => {
        if (item.paymentType === 'CREDIT') {
          totalSum += amountToNumber(item.amount);
        }
      });
      return totalSum;
    },
    isVisibleForteBtn() {
      return this.renderComponentFlag && this.forteConfig.isConfigured;
    },
    amountRemaining() {
      let totalRemainingAmount = amountToNumber(this.depositAmount);
      for (let i = 0; i < (this.internalValue || []).length; i += 1) {
        totalRemainingAmount -= amountToNumber(this.internalValue[i].amount);
      }
      return totalRemainingAmount;
    },
  },
  mounted() {
    this.updateForteConfig();
  },
  methods: {
    ...mapActions('ForteService', [
      'fetchForteConfig',
    ]),
    amountFormat,
    focusAmount,
    loadInitialValues(item) {
      return {
        ...item,
        amount: amountFormat(amountToNumber(item.amount)),
      };
    },
    shortcutHandler(e, item, isAddNew = false, index) {
      if (isAltC(e) || isAltR(e) || isAltE(e)) {
        this.changePaymentTypeByShortcut(e, item);
      }
      if (isTabOrPlus(e)) {
        if ((index + 1) === (this.internalValue || []).length) {
          this.sumAmount();
          this.somethingChanged();
          if ((amountToNumber(this.depositAmount) > this.sumAmount()) && isAddNew) {
            this.addByShortcut(e);
          }
        }
      }
    },
    addByShortcut(e) {
      e.preventDefault();
      this.addOneAndFocusIfFirst();
    },
    changePaymentTypeByShortcut(e, item) {
      e.preventDefault();
      const itemIndex = this.internalValue.indexOf(item);
      if (isAltC(e)) {
        const itemPaymentType = this.internalValue[itemIndex].paymentType;
        this.internalValue[itemIndex].paymentType = (itemPaymentType === 'CHECK') ? 'CASH' : 'CHECK';
      }
      if (isAltR(e)) {
        this.internalValue[itemIndex].paymentType = 'CREDIT';
      }
      if (isAltE(e)) {
        this.internalValue[itemIndex].paymentType = 'EFT';
      }
      this.somethingChanged();
    },
    addOneAndFocusIfFirst() {
      this.addOne();
      this.$nextTick(() => {
        const index = (this.internalValue || []).length;
        this.$refs[`item_${index - 1}`].focus();
      });
    },
    somethingChanged() {
      this.$emit('input', this.internalValue);
    },
    addOne() {
      this.$emit('input', [
        ...this.internalValue,
        {
          ...defaultItem,
        },
      ]);
      return true;
    },
    removeOne(item) {
      // const indexOfRemove = this.internalValue.indexOf(item);
      this.$emit('input', [
        ...this.internalValue.filter((candidate) => item !== candidate),
      ]);
    },
    sumAmount() {
      let totalSum = 0.00;
      for (let i = 0; i < (this.internalValue || []).length; i += 1) {
        totalSum += amountToNumber(this.internalValue[i].amount);
      }
      this.amountSum = totalSum;
      return totalSum;
    },
    forteResponse(response) {
      this.$emit('forteResponse', response);
    },
    async updateForteConfig() {
      // Remove forte-component from the DOM
      this.renderComponentFlag = false;
      // call forte event
      await this.fetchForteConfig({
        scope: 'odconfig',
        forteAmount: this.totalCreditAmount.toString(),
      });
      this.$nextTick(() => {
        // Add the forte-component back in
        this.renderComponentFlag = true;
      });
    },
  },
};
</script>

<style lang="sass">
  .trust-amount input
    text-align: right
</style>
