<template>
  <v-dialog class="odtransneweditdiag"
    v-model="dialog" max-width="85vw"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        id="add-new-transaction-item"
        v-if="canAdd"
        class="mx-2" color="primary"
        fab icon small
        :disabled="selectedProp.length !== 0"
        v-bind="attrs" v-on="on"
        @click="newItem"
      >
        <v-icon>
          {{icons.mdiPlus}}
        </v-icon>
      </v-btn>
    </template>
    <div v-if="editedItem.type === 'VOUCHER'">
      <VoucherDetails
        :editedItem="editedItem"
      ></VoucherDetails>
    </div>
    <div v-else>
      <DepositDetails
        :editedItem="editedItem"
      ></DepositDetails>
    </div>
  </v-dialog>
</template>

<script>
import {
  mdiPlus,
} from '@mdi/js';

import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
} from 'vuex';
import {
  isCashFnKey,
  isCreditFnKey,
  isInsert,
} from '../../util/shared/vue-global';
import {
  toIsoDate,
  toFiscalYear,
} from '../../util/shared/tmc-global';

import VoucherDetails from './VoucherDetails.vue';
import DepositDetails from './DepositDetails.vue';
import { fields } from './DepositVoucher.shared';

export default {
  name: 'TransactionList',
  components: {
    VoucherDetails,
    DepositDetails,
  },
  props: {
    canAdd: {
      type: Boolean,
      default: false,
    },
    selectedProp: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    icons: {
      mdiPlus,
    },
  }),
  computed: {
    ...mapState({
      nextVoucherNumber: (state) => state.OD.nextVoucherNumber,
      nextRegistrationNumber: (state) => state.OD.nextRegistrationNumber,
      deptBalanceBefore: (state) => state.OD.deptBalanceBefore,
      criteria: (state) => state.OD.criteria,
      stateDialog: (state) => state.OD.newEditDialog,
      editedItem: (state) => state.OD.newEditItem,
      newEditIndex: (state) => state.OD.newEditIndex,
      registerRange: (state) => state.OD.registerRange,
      voucherRegPolling: (state) => state.OD.voucherRegPolling,
      odSettingItem: (state) => state.SystemConfig.odSettingItem,
      preWhatStr: (state) => state.OD.preWhatStr,
      preWhomStr: (state) => state.OD.preWhomStr,
    }),
    ...mapGetters([
      'todaysDate',
    ]),
    dialog: {
      get() {
        return this.stateDialog;
      },
      set(value) {
        this.setNewEditDialog(value);
      },
    },
    isNewItem() {
      return this.newEditIndex === -1;
    },
  },
  created() {
    window.addEventListener('keydown', this.shortcutHandler);
    this.setNewEditItem(this.defaultItem());
    // async load items needed for editing
    this.$store.dispatch('OD/loadDepartments');
    const criteria = {
      fiscalYear: this.editedItem.fiscalYear,
    };
    this.loadWhoms(criteria);
    this.loadWhats(criteria);
  },
  destroyed() {
    window.removeEventListener('keydown', this.shortcutHandler);
  },
  methods: {
    ...mapActions('OD', [
      'loadWhats',
      'loadWhoms',
    ]),
    ...mapMutations('OD', [
      'setNewEditItem',
      'setNewEditDialog',
      'setStats',
      'setNewEditIndex',
      'setVoucherRegPolling',
      'setVoucherRegPulse',
      'setAutoFocus',
    ]),
    ...mapMutations('files', [
      'setFiles',
    ]),
    shortcutHandler(e) {
      if (isCashFnKey(e)) {
        e.preventDefault();
        this.editedItem.depositType = 'CASHCHECK';
        return;
      }
      if (isCreditFnKey(e)) {
        e.preventDefault();
        this.editedItem.depositType = 'CREDIT';
        return;
      }
      if (isInsert(e)) {
        this.newItem();
        this.dialog = true;
        // important as per v-dialog documentation
        e.stopPropagation();
      }
    },
    defaultItem() {
      const defaultObj = { ...fields() };
      return defaultObj;
    },
    // new item type defaults
    newItem() {
      this.setFiles([]);
      this.setNewEditIndex(-1);
      this.setNewEditItem(this.defaultItem());
      this.setStats({
        odfydeptcache: {
          balance: 0.0, // show 0 until a department is chosen
        },
      });
      this.editedItem.register = this.todaysDate;
      this.editedItem.fiscalYear = toFiscalYear(new Date(this.todaysDate));
      this.editedItem.status = 'OUTSTANDING';

      if (this.criteria.types && this.criteria.types.length > 0) {
        const hasVoucher = this.criteria.types.filter((t) => t === 'VOUCHER').length > 0;
        if (hasVoucher) {
          this.editedItem.type = 'VOUCHER'; // most common to enter
        } else {
          const hasDeposit = this.criteria.types.filter((t) => t === 'DEPOSIT').length > 0;
          if (hasDeposit) {
            if (this.odSettingItem.unregisteredDep && this.isNewItem) {
              this.editedItem.type = 'UNREGISTERED';
            } else {
              this.editedItem.type = 'DEPOSIT'; // 2nd most common to enter
            }
          } else {
            // otherwise, just go w/ the first one we find
            [this.editedItem.type] = this.criteria.types;
          }
        }
      }

      if (this.criteria.departments && this.criteria.departments.length > 0) {
        [this.editedItem.department] = this.criteria.departments; // [0]
      }
      // This is bug, irrelevant for now, it became multiple selection
      // if (this.editedItem.type === 'DEPOSIT' || this.editedItem.type === 'UNREGISTERED') {
      //   this.editedItem.depositType = this.criteria.depositType;
      // }
      if (this.editedItem.type === 'VOUCHER') {
        this.editedItem.whatStr = this.preWhatStr;
        this.editedItem.whomStr = this.preWhomStr;
      }

      // auto focus selected fields
      if (!this.editedItem.department) {
        const updateAutoFocus = {
          isDepartment: true,
          isAmount: false,
          isToWhom: false,
        };
        this.setAutoFocus(updateAutoFocus);
      } else if (this.editedItem.type === 'VOUCHER') {
        const updateAutoFocus = {
          isDepartment: false,
          isAmount: false,
          isToWhom: true,
        };
        this.setAutoFocus(updateAutoFocus);
      } else {
        this.setAutoFocus();
      }

      // when depatment already selected, fetch latest department balance before
      if (this.editedItem.department && this.editedItem.fiscalYear) {
        this.$store.dispatch(
          'OD/loadDeptBalance',
          {
            department: this.editedItem.department,
            fiscalYear: this.editedItem.fiscalYear,
          },
        );
      }

      // new items should verify their reg/vouch #'s are not stale
      if (this.voucherRegPolling === undefined) {
        const voucherRegPolling = setInterval(
          this.determineNextTransactionNumbers,
          1200,
        );
        this.setVoucherRegPolling(voucherRegPolling);
      }
      this.$nextTick(() => {
        if (this.dialog) {
          this.determineNextTransactionNumbers();
          this.determineRegistrationDate();
        }
      });
    },
    determineNextTransactionNumbers() {
      if (this.dialog && this.isNewItem) {
        const loadStatsPromise = this.$store.dispatch(
          'OD/loadTransStats',
          {
            type: this.editedItem.type,
            department: this.editedItem.department || undefined,
            fiscalYear: this.editedItem.fiscalYear,
          },
        );
        loadStatsPromise.then(() => {
          let animate = false;
          if ((this.editedItem.voucher !== this.nextVoucherNumber)
            || (this.editedItem.registration !== this.nextRegistrationNumber)) {
            animate = true;
          }
          this.editedItem.voucher = this.nextVoucherNumber;
          this.editedItem.registration = this.nextRegistrationNumber;
          if (animate) {
            this.$nextTick(() => {
              this.setVoucherRegPulse(true);
            });
          } else {
            this.setVoucherRegPulse(false);
          }
        });
      } else if (!this.dialog) {
        // should only fire this func on an interval while the dialog is open
        // rare / weird UI things can cause
        // this (like clicking '+' and pressing 'Esc' simultaneously)
        console.warn('Abandoned interval. Clearing..');
        clearInterval(this.voucherRegPolling);
        this.setVoucherRegPolling(undefined);
        // Commenting for now, will uncomment if above logic will give some error
        // if (this.voucherRegPolling) {
        //   clearInterval(this.voucherRegPolling);
        //   this.setVoucherRegPolling(undefined);
        // }
      }
    },
    determineRegistrationDate() {
      if (this.isNewItem) {
        if (this && this.odSettingItem && this.odSettingItem.usedLastRegister) {
          this.$store.dispatch('OD/loadRegisterRange').then(() => {
            this.editedItem.register = new Date(this.registerRange.max) > new Date()
              ? this.registerRange.max : toIsoDate(new Date());
          }).catch((e) => {
            console.warn(e);
            this.editedItem.register = toIsoDate(new Date());
          });
        } else {
          this.editedItem.register = toIsoDate(new Date());
        }
        this.editedItem.fiscalYear = toFiscalYear(new Date(this.editedItem.register));
      }
    },
  },
};
</script>

<style lang="sass">

@keyframes color-anim-pulse
  0%
    background-color: #fff
    opacity: 1.0
  80%
    background-color: #1976D2
    opacity: 0.6
  100%
    background-color: #fff
    opacity: 1.0

.color-anim-pulse input
  animation-name: color-anim-pulse
  animation-duration: 800ms

</style>
