<template>
<div>
  <v-container fluid class="TransactionList">
    <v-row no-gutters>
      <v-col cols="12" sm="6" md="4">
        <h5>Filter by</h5>
      </v-col>
    </v-row>
    <TransactionCriteria
      v-show="!isPrintScreen"
      :defaultType="defaultType"
      :propHeaders="headers"
      :chunkID="chunkID"
      @resetValues="resetValues"
    />
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="transactions"
      :options.sync="options"
      :items-per-page.sync="getSettings.itemsperpage"
      :server-items-length="getSettings.serverItemsLength"
      :footer-props="getSettings.footerProps"
      @update:page="handlePage"
      :loading="loading"
      :loading-text="loadingText"
      show-select
      dense
      class="elevation-1 mt-2 tmc-odtrans-dt"
      :item-class="dataItem"
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-toolbar flat class="mr-0 pr-0">
          <v-spacer />
          <div>
            <v-data-footer
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            v-bind="getSettings.footerProps"
            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
            :items-per-page.sync="getSettings.itemsperpage"
            :server-items-length="getSettings.serverItemsLength"
            />
          </div>
        </v-toolbar>
        <v-toolbar flat>
          <v-text-field id="od-trans-select-mark-date"
            class="mt-6 shrink"
            v-model="markingDateForItems"
            label="Select Date to Mark"
            type="date"
          ></v-text-field>
          <div>
            <v-tooltip bottom v-if="typesSelected.includes('UNREGISTERED')">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2"
                  color="primary"
                  fab
                  icon
                  small
                  :disabled="!canMarkItemsPaidCancel"
                  @click="assignRegNums"
                >
                  <v-icon>
                    {{ icons.mdiAlphaRCircle }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Assign Registration Numbers</span>
            </v-tooltip>
          </div>
          <div>
            <v-tooltip bottom v-if="bulkActionDeposit">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2"
                  color="primary"
                  id="bulk-mark-elect-deposit-paid"
                  fab
                  icon
                  small
                  :disabled="!canMarkItemsPaidCancel"
                  @click="bulkMarkPaid"
                >
                  <v-icon>
                    {{ icons.mdiAlphaPCircle }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Mark Paid</span>
            </v-tooltip>
          </div>
          <div>
            <v-tooltip bottom v-if="typesSelected.includes('VOUCHER')">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  id="voucher-bulk-mark-paid"
                  class="mx-2"
                  color="primary"
                  fab
                  icon
                  small
                  :disabled="!canMarkItemsPaidCancel"
                  @click="itemsBulkUpdate('PAID')"
                >
                  <v-icon>
                    {{ icons.mdiAlphaPCircle }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Mark Paid</span>
            </v-tooltip>
          </div>
          <div>
            <v-tooltip bottom v-if="typesSelected.includes('VOUCHER')">
              <template v-slot:activator="{ on, attrs }">
                <v-btn id="od-trans-cancelled"
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2"
                  color="primary"
                  fab
                  icon
                  small
                  :disabled="!canMarkItemsPaidCancel"
                  @click="markItemsCancelledInBulk"
                >
                  <v-icon>
                    {{ icons.mdiAlphaCCircle }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Mark Cancelled</span>
            </v-tooltip>
          </div>
          <div>
            <v-tooltip bottom v-if="typesSelected.includes('VOUCHER') || bulkActionDeposit">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2"
                  color="primary"
                  id="mark-trans-unpaid"
                  fab
                  icon
                  small
                  :disabled="
                    !canMarkItemsUnpay || !['VOUCHER', 'DEPOSIT'].includes(currentTransactionType)
                  "
                  @click="markUnpaid"
                >
                  <v-icon>
                    {{ icons.mdiAlphaUCircle }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ unpaidCaption }}</span>
            </v-tooltip>
          </div>
          <div>
            <v-tooltip bottom v-if="typesSelected.includes('VOUCHER')">
              <template v-slot:activator="{ on, attrs }">
                <v-btn id="od-trans-void"
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2"
                  color="primary"
                  fab
                  icon
                  small
                  :disabled="!canMarkItemsPaidCancel"
                  @click="markItemsVoidInBulk"
                >
                  <v-icon>
                    {{ icons.mdiAlphaVCircle }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Mark Void</span>
            </v-tooltip>
          </div>
          <div>
            <v-tooltip
              bottom
              v-if="typesSelected.includes('DEPOSIT') && isUnregisteredDepositAllowed"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn id="odTransBackBurgerIcon"
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2"
                  color="primary"
                  fab
                  icon
                  small
                  :disabled="!canMarkItemsPaidCancel"
                  @click="assignUnregNums"
                >
                  <v-icon>
                    {{ icons.mdiBackburger }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Unregister Deposit</span>
            </v-tooltip>
          </div>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-icon id="odTransDeleteIcon"
            v-if="canDelete"
            class="mx-2"
            color="primary"
            fab
            icon
            small
            :disabled="selected.length === 0"
            @click="deleteItems"
          >
            {{ icons.mdiDelete }}
          </v-icon>
          <v-divider class="mx-4" inset vertical></v-divider>

          <!-- Mark Printed -->
          <div>
            <v-tooltip bottom v-if="typesSelected.includes('VOUCHER')">
              <template v-slot:activator="{ on, attrs }">
                <v-btn id="odTransPrintVoucherCheckstBtn"
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2"
                  color="primary"
                  fab
                  icon
                  small
                  :disabled="!vouchersSelected"
                  @click="vouchersBulkPrint"
                >
                  <v-icon>
                    {{ icons.mdiPrinter }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Print voucher checks</span>
            </v-tooltip>
          </div>

          <!-- Deposit Ticket -->
          <div>
            <v-tooltip bottom v-if="unregisteredOrDeposit">
              <template v-slot:activator="{ on, attrs }">
                <v-btn id="odTransPrintDepositTicketBtn"
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2"
                  color="primary"
                  fab
                  icon
                  small
                  :disabled="!unregisteredOrDepositsSelected"
                  @click="depositTicketsBulkPrint"
                >
                  <v-icon>
                    {{ icons.mdiPrinter }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Print Deposit Ticket</span>
            </v-tooltip>
          </div>
          <!-- Deposit Trust Receipt -->
          <div>
            <v-tooltip bottom v-if="onlyTrustReceiptsSelected">
              <template v-slot:activator="{ on, attrs }">
                <v-btn id="odTransPrintTrustReceiptBtn"
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2"
                  color="primary"
                  fab
                  icon
                  small
                  :disabled="!onlyTrustReceiptsSelected"
                  @click="trustReceiptBulkPrint"
                >
                  <v-icon>
                    {{ icons.mdiPrinter }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Print Trust Receipt</span>
            </v-tooltip>
          </div>
          <v-spacer />
          <TransactionNewEditDialog :canAdd="canAdd" :selectedProp="selected" />
        </v-toolbar>
      </template>

      <!--
        TODO - Breaks multi-select..how can we fix it?
        <template v-slot:[`item`]="{ item, headers }">
          <tr
            @click="editItem(item)"
          >
            <td
              class="text-start"
              v-for="header in headers" :key="header.value"
            >
              {{resolveSubProp(header.value, item)}}
            </td>
          </tr>
        </template>
        WORKAROUND (below) - one slot per possible column
        -->
      <template v-slot:[`item.type`]="{ item, value }">
        <td class="text-start clickable" @click="editItem(item)">
          {{ shorterType(value) }}
        </td>
      </template>
      <template v-slot:[`item.hasFiles`]="{ item, value }">
        <td class="text-start clickable">
          <span v-if="value">
            <UploadFile id="odTransUploadFile"
              class="odtrans uploadFile"
              bucketName="OD"
              program="OD"
              parentObjectType="ODTransaction"
              :parentObjectId="item.id"
              :itemIsSaved="true"
              :showText="false"
            ></UploadFile>
          </span>
        </td>
      </template>
      <template v-slot:[`item.registration`]="{ item, value }">
        <td class="text-start clickable" @click="editItem(item)">{{ value }}</td>
      </template>
      <template v-slot:[`item.register`]="{ item, value }">
        <td class="text-start clickable" @click="editItem(item)">{{ value }}</td>
      </template>
      <template v-slot:[`item.finalized`]="{ item, value }">
        <td class="text-start clickable" @click="editItem(item)">{{ value }}</td>
      </template>
      <template v-slot:[`item.departmentDetail.dept`]="{ item, value }">
        <td class="text-start clickable" @click="editItem(item)">{{ value }}</td>
      </template>
      <template v-slot:[`item.departmentDetail.description`]="{ item, value }">
        <td class="text-start clickable" @click="editItem(item)">{{ value }}</td>
      </template>
      <template v-slot:[`item.depositType`]="{ item, value }">
        <td class="text-start clickable" @click="editItem(item)">{{ value }}</td>
      </template>
      <template v-slot:[`item.remarks`]="{ item, value }">
        <td class="text-start clickable" @click="editItem(item)">{{ limitRemarkLength(value) }}</td>
      </template>
      <template v-slot:[`item.amount`]="{ item, value }" @click="editItem(item)">
        <span class="pointer" @click="editItem(item)">{{ amountFormat(value) }}</span>
      </template>
      <template v-slot:[`item.status`]="{ item, value }">
        <td class="text-start clickable" @click="editItem(item)">
          {{ isDepositAndOutstanding(item) ? "" : value }}
        </td>
      </template>
      <template v-slot:[`item.voucher`]="{ item, value }">
        <td class="text-start clickable" @click="editItem(item)">{{ value }}</td>
      </template>
      <template v-slot:[`item.whomStr`]="{ item, value }">
        <td class="text-start clickable" @click="editItem(item)">{{ value }}</td>
      </template>
      <template v-slot:[`item.whatStr`]="{ item, value }">
        <td class="text-start clickable" @click="editItem(item)">{{ value }}</td>
      </template>
      <template v-slot:[`item.hasPrinted`]="{ item, value }">
        <td class="text-start clickable" @click="editItem(item)">
          {{ value >= 1 ? "Yes" : "No" }}
        </td>
      </template>
      <template v-if="selectedItemsAmount !== 0" v-slot:footer>
        <br />
        <div class="table-footer-prepend" :class="selectedItemsAmount < 0 ? 'red--text' : ''">
          {{ `Total: ${amountFormat(selectedItemsAmount)}` }}
        </div>
      </template>
    </v-data-table>
    <!--make below dialog box generic-->
    <v-dialog
      v-model="cancelledDialog"
      persistent
      width="35vw"
      height="145vh">
    <v-card>
      <v-card-title>
        <span class="headline">Mark vouchers "Cancelled By Statute?"</span>
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="cancelledByStatute(true)"> Yes </v-btn>
        <v-btn color="primary" text @click="cancelledByStatute(false)"> No </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </v-container>
  <SummaryDialog
      v-model="sumOfTrans.isDisplay"
      :sumOfTrans="sumOfTrans"
    />
</div>
</template>

<script>
import {
  mdiAlphaRCircle,
  mdiAlphaPCircle,
  mdiAlphaUCircle,
  mdiAlphaCCircle,
  mdiBackburger,
  mdiAlphaVCircle,
  mdiDelete,
  mdiPrinter,
  // mdiAttachment,
} from '@mdi/js';

import {
  mapState, mapGetters, mapMutations, mapActions,
} from 'vuex';

import TransactionCriteria from './TransactionCriteria.vue';
import TransactionNewEditDialog from './TransactionNewEditDialog.vue';
import UploadFile from '../common/UploadFile.vue';
import {
  toIsoDate,
  isNotBeforeDate,
  defaultDurationOfCorrection,
} from '../../util/shared/tmc-global';
import { amountFormat, amountToNumber } from '../../util/shared/vue-global';
import { clients } from '../../util/clients';
import SummaryDialog from './SummaryDialog.vue';
import { getDefaultSumOfTrans, fetchODAmountTotal } from './odTransaction.util';

const { backendRest } = clients.direct;

const bulkUpdatableDeposits = ['CREDIT', 'EFT', 'TRUST', 'CASHCHECK', 'OTHER'];

export default {
  name: 'TransactionList',
  components: {
    TransactionCriteria,
    TransactionNewEditDialog,
    UploadFile,
    SummaryDialog,
  },
  props: {
    canAdd: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    defaultType: {
      type: String,
      default: 'DEPOSIT',
    },
  },
  data: () => ({
    options: {},
    icons: {
      mdiAlphaRCircle,
      mdiAlphaPCircle,
      mdiAlphaUCircle,
      mdiAlphaCCircle,
      mdiAlphaVCircle,
      mdiBackburger,
      mdiDelete,
      mdiPrinter,
    },
    selected: [],
    delayTimerId: undefined,
    markingDateForItems: undefined,
    overlay: false,
    sumOfTrans: getDefaultSumOfTrans(),
    chunkID: null,
    cancelledDialog: false,
  }),
  watch: {
    options: {
      handler() {
        const { sortBy, sortDesc } = this.options;
        if (sortBy.length !== 0) {
          const value = sortDesc[0] ? 'desc' : 'asc';
          const sorting = [{ column: sortBy[0], direction: value }];
          this.$store.commit('OD/setSortBy', sorting);
          this.$store.dispatch('OD/searchTransactions');
        }
      },
    },
    selected(newvalue) {
      this.$store.commit('OD/setselectedrecords', newvalue);
    },
  },
  computed: {
    ...mapState({
      headers: (state) => state.OD.headers,
      transactions: (state) => state.OD.transactions,
      currentTransactionType: (state) => state.OD.currentTransactionType,
      loading: (state) => state.gridLoading,
      loadingText: (state) => state.gridLoadingText,
      typesSelected: (state) => state.OD.criteria.types,
      depositTypeSelected: (state) => state.OD.criteria.depositType || [],
      odSettingItem: (state) => state.SystemConfig.odSettingItem,
      nextRegistrationNumber: (state) => state.OD.nextRegistrationNumber,
      visitedItem: (state) => state.OD.visitedTransaction,
      isPrintScreen: (state) => state.isPrintScreen,
    }),
    ...mapGetters(['todaysDate']),
    ...mapGetters('user', ['getSettings']),
    unregisteredOrDeposit() {
      return this.typesSelected.includes('DEPOSIT') || this.typesSelected.includes('UNREGISTERED');
    },
    bulkActionDeposit() {
      return (
        this.typesSelected.includes('DEPOSIT')
        && (this.depositTypeSelected.length === 0 || this.isBulkUpdatable(this.depositTypeSelected))
      );
    },
    canMarkItemsPaidCancel() {
      const hasSelectedItems = this.selected.length > 0;
      const hasDate = (this.markingDateForItems || '').length > 0;
      return hasSelectedItems && hasDate;
    },
    isUnregisteredDepositAllowed() {
      return this.odSettingItem && this.odSettingItem.unregisteredDep;
    },
    canMarkItemsUnpay() {
      const hasSelectedItems = this.selected.length === 0;
      const hasDate = (this.markingDateForItems || '').length > 0;
      return hasSelectedItems && hasDate;
    },
    vouchersSelected() {
      const hasSelectedItems = this.selected.length > 0;
      const searchingVouchers = this.typesSelected.includes('VOUCHER');
      return hasSelectedItems && searchingVouchers;
    },
    unregisteredOrDepositsSelected() {
      const hasSelectedItems = this.selected.length > 0;
      const searchingDeposits = this.unregisteredOrDeposit;
      return hasSelectedItems && searchingDeposits;
    },
    onlyTrustReceiptsSelected() {
      const trustReceipts = this.selected.filter(
        (i) => i.type === 'DEPOSIT' && i.depositType === 'TRUST',
      );
      return this.selected.length === trustReceipts.length && this.selected.length > 0;
    },
    unpaidCaption() {
      let caption;
      switch (this.currentTransactionType) {
        case 'VOUCHER':
          caption = 'Mark Unpaid';
          break;
        case 'DEPOSIT':
          caption = 'Mark Unpaid CC, EFT, CASH, CHECK';
          break;
        default:
          caption = '';
          break;
      }
      return caption;
    },
    selectedItemsAmount() {
      const amountSum = this.selected.reduce((acc, obj) => acc + amountToNumber(obj.amount), 0);
      return this.currentTransactionType === 'VOUCHER' ? 0 - amountSum : amountSum;
    },
    durationOfCorrection() {
      if (this && this.odSettingItem && this.odSettingItem.durationOfCorrection) {
        return parseInt(this.odSettingItem.durationOfCorrection, 10) || defaultDurationOfCorrection;
      }
      return defaultDurationOfCorrection;
    },
  },
  created() {
    this.markingDateForItems = this.todaysDate; // by default
    this.loadConfig({ requiredScope: 'odconfig' });
    this.getChuckID();
    this.getSumOfTransactions();
  },
  methods: {
    ...mapActions(['reAuth']),
    ...mapActions('OD', ['upsTransaction', 'loadFiscalYears', 'loadTransaction']),
    ...mapActions('SystemConfig', ['loadConfig']),
    ...mapActions('files', ['loadAttachedFiles']),
    ...mapMutations('OD', [
      'setHeaders',
      'setNewEditDialog',
      'setNewEditItem',
      'setNewEditIndex',
      'setCriteria',
      'setAutoFocus',
    ]),
    amountFormat,
    limitRemarkLength(remark) {
      if (!remark) return '';
      return remark.length > 80 ? `${remark.substring(0, 80)}...` : remark;
    },
    getChuckID() {
      this.chunkID = ((this.$route || {}).query || {}).chunkID;
    },
    isBulkUpdatable(depositTypes) {
      return bulkUpdatableDeposits.find((d) => depositTypes.includes(d));
    },
    dataItem(item) {
      return item.id === this.visitedItem ? 'data-item' : 'data-item-normal';
    },
    handlePage(page) {
      const { itemsperpage } = this.getSettings;
      const skip = (page - 1) * itemsperpage;
      const limit = itemsperpage;
      this.setCriteria({ skip, limit });
      this.$store.dispatch('OD/searchTransactions');
    },
    async vouchersBulkPrint() {
      const { jwt } = await this.reAuth();

      const printUpserts = this.selected.map(({ id }) => {
        const newHasPrinted = 1;
        const upsert = {
          transaction: {
            id,
            hasPrinted: newHasPrinted,
          },
        };
        return this.upsTransaction(upsert);
      });
      await Promise.all(printUpserts);

      const odTransId = this.selected.map((t) => t.id).join(',');
      const url = `${backendRest.defaults.baseURL}/vouchercheck?odTransId=${odTransId}&token=${jwt}`;
      window.open(url, '_blank');
    },
    async depositTicketsBulkPrint() {
      const { jwt } = await this.reAuth();
      const odTransId = this.selected.map((t) => t.id).join(',');
      const url = `${backendRest.defaults.baseURL}/depositticket?odTransId=${odTransId}&token=${jwt}`;
      window.open(url, '_blank');
    },
    async trustReceiptBulkPrint() {
      const { jwt } = await this.reAuth();
      const odTransId = this.selected.map((t) => t.id).join(',');
      const url = `${backendRest.defaults.baseURL}/trustreceipt?odTransId=${odTransId}&token=${jwt}`;
      window.open(url, '_blank');
    },
    async assignRegNums() {
      if (window.confirm(`Are you sure want to register ${this.selected.length} deposit(s)?`)) {
        const sortedSelected = this.selected.sort((a, b) => {
          if (a.register > b.register) {
            return 1;
          }
          return -1;
        });
        for (let i = 0; i < sortedSelected.length; i += 1) {
          const item = sortedSelected[i];
          if (item.type === 'UNREGISTERED') {
            await this.$store.dispatch('OD/loadTransStats', {
              type: 'DEPOSIT',
              department: item.department || undefined,
              fiscalYear: item.fiscalYear,
            });
            const upsItem = {
              id: item.id,
              type: 'DEPOSIT',
              registration: this.nextRegistrationNumber,
              register: this.markingDateForItems,
            };
            await this.$store.dispatch('OD/upsTransaction', {
              transaction: upsItem,
            });
          } else {
            this.$store.dispatch(
              'flashInfo',
              'One or more selected transactions were not unregistered transaction(s).',
            );
          }
        }
        this.$store.dispatch('OD/searchTransactions');
        this.selected = [];
      }
    },
    async assignUnregNums() {
      if (window.confirm(`Are you sure want to unregister ${this.selected.length} deposit(s)?`)) {
        const sortedSelected = this.selected.sort((a, b) => {
          if (a.register > b.register) {
            return 1;
          }
          return -1;
        });
        for (let i = 0; i < sortedSelected.length; i += 1) {
          const item = sortedSelected[i];
          if (item.type === 'DEPOSIT') {
            await this.$store.dispatch('OD/loadTransStats', {
              type: 'UNREGISTERED',
              department: item.department || undefined,
              fiscalYear: item.fiscalYear,
            });
            const upsItem = {
              id: item.id,
              type: 'UNREGISTERED',
              registration: this.nextRegistrationNumber,
              register: this.markingDateForItems,
            };
            await this.$store.dispatch('OD/upsTransaction', {
              transaction: upsItem,
            });
          } else {
            this.$store.dispatch(
              'flashInfo',
              'One or more selected transactions were not registered transaction(s).',
            );
          }
        }
        this.$store.dispatch('OD/searchTransactions');
        this.selected = [];
      }
    },
    canBeMarkedPaid(item) {
      return this.isDepositAndOutstanding(item) && this.isBulkUpdatable([item.depositType]);
    },
    isTrustDepositHasRecItems(item) {
      return item.trustItems && item.trustItems.length > 0;
    },
    trustItemsMarkingPaidOrUnpaid(item, isMarkPaid) {
      const trustItems = [];
      let hasBulkUpdatableItems = false;
      item.forEach((rec) => {
        if (['EFT', 'CREDIT', 'CASH', 'CHECK'].includes(rec.paymentType)) {
          hasBulkUpdatableItems = true;
          trustItems.push({
            ...rec,
            isPaid: isMarkPaid,
          });
        } else {
          trustItems.push(rec);
        }
      });
      return [trustItems, hasBulkUpdatableItems];
    },
    markableItemsMarkingPaidOrUnpaid(item, isMarkPaid) {
      const creditItems = item.map((m) => ({
        ...m,
        isCleared: isMarkPaid,
      }));
      return creditItems;
    },
    bulkMarkPaid() {
      if (window.confirm(`Are you sure want to mark ${this.selected.length} deposit(s) paid?`)) {
        for (let i = 0; i < this.selected.length; i += 1) {
          const item = this.selected[i];
          if (this.canBeMarkedPaid(item)) {
            let creditItems = [];
            let trustItems = [];
            let hasBulkUpdatableItems = true;
            if (this.isDepositHasBulkItems(item)) {
              creditItems = this.markableItemsMarkingPaidOrUnpaid(item.creditItems, true);
            }
            if (this.isTrustDepositHasRecItems(item)) {
              [trustItems, hasBulkUpdatableItems] = this.trustItemsMarkingPaidOrUnpaid(
                item.trustItems,
                true,
              );
            }
            if (hasBulkUpdatableItems) {
              const upsItem = {
                id: item.id,
                status: 'PAID',
                finalized: this.markingDateForItems,
                creditItems,
                trustItems,
              };
              this.$store.dispatch('OD/upsTransaction', { transaction: upsItem });
            } else {
              this.$store.dispatch(
                'flashInfo',
                'One or more transactions were already paid items.',
              );
            }
          } else {
            this.$store.dispatch(
              'flashInfo',
              'One or more transactions were already paid items.',
            );
          }
        }
        this.selected = [];
      }
    },
    cancelledByStatute(isCancelledByStatute) {
      this.cancelledDialog = false;
      if (isCancelledByStatute) {
        this.updateItemsInBulk('CANCELLED', 'whatStr', 'CANCELLED BY STATUTE');
      } else {
        this.updateItemsInBulk('CANCELLED');
      }
    },
    voucherMustOSForMarkingPaid(item) {
      if (item.type === 'VOUCHER') {
        return item.status === 'OUTSTANDING';
      }
      return true;
    },
    updateItemsInBulk(statusMark, keyToUpate, valueToUpdate) {
      for (let i = 0; i < this.selected.length; i += 1) {
        const item = this.selected[i];
        if (item.status !== statusMark && this.voucherMustOSForMarkingPaid(item)) {
          const upsItem = {
            id: item.id,
            status: statusMark,
            finalized: this.markingDateForItems,
          };
          if (keyToUpate) {
            upsItem[keyToUpate] = valueToUpdate;
          }
          this.$store.dispatch('OD/upsTransaction', { transaction: upsItem });
        } else {
          this.$store.dispatch(
            'flashInfo',
            'One or more transactions were already paid/cancelled/void.',
          );
        }
      }
      this.selected = [];
    },
    markItemsVoidInBulk() {
      if (window.confirm(`Are you sure want to update ${this.selected.length} record/s?`)) {
        this.updateItemsInBulk('VOID', 'amount', 0.0);
      }
    },
    markItemsCancelledInBulk() {
      if (window.confirm(`Are you sure want to update ${this.selected.length} record/s?`)) {
        this.cancelledDialog = true;
      }
    },
    itemsBulkUpdate(statusMark) {
      if (window.confirm(`Are you sure want to update ${this.selected.length} record/s?`)) {
        this.updateItemsInBulk(statusMark);
      }
    },
    shorterType(value) {
      switch (value) {
        case 'DEPOSIT':
          return 'd';
        case 'VOUCHER':
          return 'v';
        case 'BEGINNINGBALANCE':
          return 'b';
        case 'CORRECTION':
          return 'c';
        case 'UNREGISTERED':
          return 'u';
        default:
          return 'd';
      }
    },
    editItem(item) {
      this.setAutoFocus();
      this.loadTransaction(item);
      this.setNewEditDialog(true);
    },
    resetValues() {
      // When transaction criteria has changed, need to reset all selected items.
      this.selected = [];
    },
    isDepositAndOutstanding(item) {
      return item.type === 'DEPOSIT' && item.status === 'OUTSTANDING';
    },
    async deleteItems() {
      // pre-pass for validation
      let anyTooOld = false;
      const reGlDates = [];
      for (let i = 0; i < this.selected.length; i += 1) {
        const item = this.selected[i];
        const registerDt = new Date(item.register);
        const isNotBefore = isNotBeforeDate(
          registerDt,
          this.todaysDate,
          this.durationOfCorrection + 1,
        );
        anyTooOld = anyTooOld || !isNotBefore;

        const todayDt = new Date(this.todaysDate);
        const diffMs = todayDt - registerDt;
        if (isNotBefore && diffMs > 0 && !reGlDates.includes(item.register)) {
          alert(`Please remember to export data for the GL for ${item.register}
            as this change will affect GL balances for ${item.register}.`);
          reGlDates.push(item.register);
        }
      }
      if (anyTooOld) {
        alert(
          `One or more transactions is more than ${this.durationOfCorrection} business days old. You `
            + 'are not allowed to delete transactions that'
            + ` are more than ${this.durationOfCorrection} business days old. Please use a correction`
            + " transaction type for today's date instead. No changes are being made.",
        );
      } else {
        if (
          window.confirm(
            `CAREFUL! Are you sure you want to DELETE ${this.selected.length} record/s?`,
          )
        ) {
          for (let i = 0; i < this.selected.length; i += 1) {
            const item = this.selected[i];
            await this.$store.dispatch('OD/delTransaction', item);
          }
          this.loadFiscalYears();
        }
        this.selected = [];
      }
    },
    async fetchUnpaidTrans() {
      const isoData = toIsoDate(this.markingDateForItems);
      const params = {
        status: 'PAID',
        fromFinalizedDate: isoData,
        toFinalizedDate: isoData,
        types: [`${this.currentTransactionType}`],
        limit: 0,
      };
      if (this.currentTransactionType === 'DEPOSIT') {
        params.depositType = bulkUpdatableDeposits;
      }
      return this.$store.dispatch('OD/searchTransactions', params);
    },
    isDepositHasBulkItems(item) {
      const ccReconciliation = this.odSettingItem && this.odSettingItem.ccReconciliation;
      return ccReconciliation && item.creditItems.length > 0;
    },
    async markUnpaid() {
      this.overlay = true;
      const isoData = toIsoDate(this.markingDateForItems);
      const response = await this.fetchUnpaidTrans();
      this.overlay = false;
      if (response.length === 0) {
        this.$store.dispatch('flashInfo', `On ${isoData} date nothing needs to be unpaid.`);
      } else if (window.confirm(`Are you sure want to unpay ${response.length} record/s?`)) {
        for (let i = 0; i < response.length; i += 1) {
          const item = response[i];
          const upsItem = {
            id: item.id,
            status: 'OUTSTANDING',
            finalized: null, // for date datatype
          };
          if (this.currentTransactionType === 'DEPOSIT') {
            let creditItems = [];
            let trustItems = [];
            if (this.isDepositHasBulkItems(item)) {
              creditItems = this.markableItemsMarkingPaidOrUnpaid(item.creditItems, false);
            }
            if (this.isTrustDepositHasRecItems(item)) {
              [trustItems] = this.trustItemsMarkingPaidOrUnpaid(item.trustItems, false);
            }
            upsItem.creditItems = creditItems;
            upsItem.trustItems = trustItems;
          }
          this.$store.dispatch('OD/upsTransaction', {
            transaction: upsItem,
          });
        }
        this.$store.dispatch('flashSuccess', 'Record/s marked Unpaid.', { root: true });
      }
    },
    async getSumOfTransactions() {
      const chunkID = this.chunkID || null;
      if (chunkID) {
        this.sumOfTrans = await fetchODAmountTotal(chunkID);
      }
    },
  },
};
</script>

<style lang="sass">
// overriding default VDataTable padding
.v-data-table
  > .v-data-footer
    margin-right: 21px
    > .v-data-footer__select
      margin-right: 18px
    > .v-data-footer__icons-before
      margin-right: 9px
    > .v-data-footer__icons-after
      margin-left: 4px
  > .v-data-table__wrapper
    > table
      > tbody,
      > thead,
      > tfoot
        > tr
          > td,
          > th
            padding: 0 8px
.table-footer-prepend
  margin: 0px 0px -45px 45px
.text-start .clickable:hover
  cursor: pointer
  font-weight: 600
.pointer:hover
  cursor: pointer
  font-weight: 600
.data-item
  background: #EEEEEE
.data-item-normal
  background: #fff
</style>
