var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 CashCheckDetails",attrs:{"dense":"","headers":_vm.headers,"items":_vm.internalValue,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex justify-end"},[(_vm.canAdd)?_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary","fab":"","icon":"","x-small":""},on:{"click":_vm.addOneAndFocusIfFirst}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1):_vm._e()],1)]},proxy:true},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"od-cashcheck-edit-desc-text",attrs:{"dense":"","rules":_vm.descriptionValidation,"label":"Edit Description","single-line":"","readonly":""},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"cash-check-amount",attrs:{"dense":"","rules":_vm.amountValidation,"single-line":""},on:{"blur":_vm.somethingChanged,"keydown":[function($event){return _vm.shortcutHandler($event, item)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.somethingChanged($event)}],"focus":_vm.focusAmount},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}})]}},(_vm.odSettingItem.hideDrawnBy !== true)?{key:"item.drawnBy",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"cash-check-drawn-by",attrs:{"dense":"","single-line":""},on:{"keydown":function($event){return _vm.shortcutHandler($event, item, true)},"focus":_vm.focusAmount,"blur":_vm.somethingChanged},model:{value:(item.drawnBy),callback:function ($$v) {_vm.$set(item, "drawnBy", $$v)},expression:"item.drawnBy"}})]}}:null,{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2 od-cash-check-delete-icon",attrs:{"small":"","tabindex":"-1"},on:{"click":function($event){return _vm.removeOne(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',{staticClass:"blue--text"},[_c('td',{attrs:{"colspan":"1","id":"cash-check-remaining-amount"}},[_vm._v(" Total Remaining : $"+_vm._s(_vm.amountFormat(_vm.amountRemaining))+" ")])]),_c('tr',{staticClass:"blue--text"},[_c('td',{attrs:{"colspan":"1","id":"total-check-count"}},[_vm._v(" Check Count : "+_vm._s(_vm.checkCount)+" ")])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }