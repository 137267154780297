export const headersByType = {
  UNREGISTERED: [
    { text: 'Type', value: 'type' },
    { text: 'Reg #', value: 'registration' },
    { text: 'Register Dt', value: 'register' },
    { text: 'Dept #', value: 'departmentDetail.dept', sortable: false },
    { text: 'Dept', value: 'departmentDetail.description', sortable: false },
    { text: 'Deposit Type', value: 'depositType' },
    { text: 'Remarks', value: 'remarks', sortable: false },
    { text: 'Amount', value: 'amount', align: 'right' },
    { text: 'Status', value: 'status' },
    { text: 'Paid', value: 'finalized' },
  ],
  DEPOSIT: [
    {
      text: 'Attachments', value: 'hasFiles', sortable: false, width: '8%',
    },
    { text: 'Type', value: 'type' },
    { text: 'Reg #', value: 'registration' },
    { text: 'Register Dt', value: 'register' },
    { text: 'Dept #', value: 'departmentDetail.dept', sortable: false },
    { text: 'Dept', value: 'departmentDetail.description', sortable: false },
    { text: 'Deposit Type', value: 'depositType' },
    { text: 'Remarks', value: 'remarks', sortable: false },
    { text: 'Amount', value: 'amount', align: 'right' },
    { text: 'Status', value: 'status' },
    { text: 'Paid', value: 'finalized' },
  ],
  VOUCHER: [
    { text: 'Attachments', value: 'hasFiles', sortable: false },
    { text: 'Type', value: 'type' },
    { text: 'Reg #', value: 'registration' },
    { text: 'Register Dt', value: 'register' },
    { text: 'Dept #', value: 'departmentDetail.dept', sortable: false },
    { text: 'Dept', value: 'departmentDetail.description', sortable: false },
    { text: 'Voucher #', value: 'voucher' },
    { text: 'To Whom', value: 'whomStr', sortable: false },
    { text: 'For What', value: 'whatStr', sortable: false },
    { text: 'Amount', value: 'amount', align: 'right' },
    { text: 'Status', value: 'status' },
    { text: 'Paid', value: 'finalized' },
    { text: 'Printed?', value: 'hasPrinted' },
  ],
  BEGINNINGBALANCE: [
    {
      text: 'Attachments', value: 'hasFiles', sortable: false, width: '8%',
    },
    { text: 'Type', value: 'type' },
    { text: 'Reg #', value: 'registration' },
    { text: 'Register Dt', value: 'register' },
    { text: 'Dept #', value: 'departmentDetail.dept', sortable: false },
    { text: 'Dept', value: 'departmentDetail.description', sortable: false },
    { text: 'Amount', value: 'amount', align: 'right' },
    { text: 'Status', value: 'status' },
  ],
  CORRECTION: [
    {
      text: 'Attachments', value: 'hasFiles', sortable: false, width: '8%',
    },
    { text: 'Type', value: 'type' },
    { text: 'Reg #', value: 'registration' },
    { text: 'Register Dt', value: 'register' },
    { text: 'Dept #', value: 'departmentDetail.dept', sortable: false },
    { text: 'Dept', value: 'departmentDetail.description', sortable: false },
    { text: 'Amount', value: 'amount', align: 'right' },
    { text: 'Status', value: 'status' },
  ],
};

export default headersByType;
