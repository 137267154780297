<template>
  <v-card class="DepositDetails"
    @keydown.enter="save"
  >
    <v-card-title>
      <span v-if="isNewItem" class="subtitle-1">New Item</span>
      <span v-if="!isNewItem" class="subtitle-1">Edit Item</span>
    </v-card-title>
    <v-card-text>
      <v-form ref="form">
        <v-container class="grey lighten-5">
          <v-row>
            <v-col cols="2">
              <v-text-field class="odtrans fiscalYear"
                id="odTransFiscalYear"
                v-model.number="internalValue.fiscalYear"
                label="Fiscal Year"
                type="number"
                @input="updateDeptAndNextTransNum"
                :rules="fiscalYearValidation"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-select class="odtrans type"
                id="odTransTypeSelect"
                :readonly="isTransactionTypeReadOnly"
                v-model="internalValue.type"
                :items="filteredTransTypes"
                :rules="reqdSelectValidation"
                @change="determineNextTransactionNumbers()"
                item-text="description"
                item-value="name"
                label="Type"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-tooltip bottom
                :open-on-hover="isTooltipVisible">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field class="odtrans registration"
                    id="odTransRegistrationText"
                    v-bind="attrs"
                    v-on="on"
                    v-model.number="internalValue.registration"
                    label="Registration #"
                    color="red"
                    type="number"
                    :readonly="!registrationOverride || isNewItem"
                    :class="voucherRegPulseClass"
                    @dblclick.stop="overrideRegistrationNumber"
                  ></v-text-field>
                </template>
                <span>{{registrationHoverCaption}}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="4">
              <ClarionDateControl class="odtrans register"
                id="odTransRegisterDate"
                v-model="internalValue.register"
                :isRequired="true"
                :isIsoDate="true"
                :label="`Register Date`"
                :rules="registerDateRules"
                @blurred="registerDateBlurred"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-select class="odtrans department"
                id="odTransDepartmentSelect"
                :autofocus="autoFocus.isDepartment"
                v-model="internalValue.department"
                :items="departments"
                :rules="reqdSelectValidation"
                item-text="caption"
                item-value="id"
                :label="departmentLabel"
                @change="updateDeptBalance"
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-text-field class="odtrans amount"
                id="odTransAmountText"
                :autofocus="autoFocus.isAmount"
                v-model="internalValue.amount"
                :rules="amountValidation"
                label="Amount"
                ref="amount"
                @blur="updateForteConfig(); roundAmount();"
                @focus="focusAmount($event); amountToNumber();"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-select class="odtrans status"
                id="odTransStatusSelect"
                v-model="internalValue.status"
                :items="statuses"
                :rules="reqdSelectValidation"
                item-text="description"
                item-value="name"
                label="Status"
                @change="onStatusChange"
              ></v-select>
            </v-col>
            <v-col cols="3">
              <ClarionDateControl class="odtrans finalized"
                id="odTransFinalPaidDate"
                v-model="internalValue.finalized"
                :isRequired="false"
                :isIsoDate="true"
                :label="`Paid Date`"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" v-if="unregisteredOrDeposit">
              <v-select id="odTransItemDepositSelect"
                class="od-trans-itemdeposit-select"
                v-model="internalValue.depositType"
                :items="clearableDepositTypes"
                item-text="description"
                item-value="name"
                label="Itemized deposit type? (if any)"
                @change="validateForm(); updateForteConfig();"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field class="odtrans remarks"
                id="odTransRemarksText"
                v-model="internalValue.remarks"
                :label="remarkCaption"
                type="string"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8" v-if="internalValue.depositType === 'TRUST'">
              <v-text-field class="odtrans depositAddress"
                id="odTransDepositAddressText"
                v-model="internalValue.depositAddress"
                label="Address"
                type="string"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="unregisteredOrDeposit">
            <v-col cols="12" sm="12" md="12">
              <div v-if="internalValue.depositType === 'CASHCHECK'
                && odSettingItem.cashCheckReconciliation">
                <CashCheckDetails id="odTransCashCheckDetails"
                  class="od-trans-cashCheck-details"
                  v-model="internalValue.cashCheckItems"
                  :depositAmount="internalValue.amount"
                  :componentKey="componentKey"
                  @triggerValidation="triggerValidation"
                />
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <div v-if="internalValue.depositType === 'CREDIT'
                && odSettingItem.ccReconciliation">
                <CreditDetails id="odTransCreditDetails"
                  class="od-trans-credit-details"
                  v-model="internalValue.creditItems"
                  :componentKey="componentKey"
                  :depositAmount="internalValue.amount"
                  @forteResponse="forteResponse"
                />
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <div v-if="internalValue.depositType === 'EFT'">
                <EFTDetails id="odTransEFTDetails"
                  class="od-trans-eftdetails"
                  v-model="internalValue.EFTItems"
                  :depositAmount="internalValue.amount"
                />
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <div v-if="internalValue.depositType === 'TRUST'
                && odSettingItem.trustReconciliation">
                <TrustDetails id="odTransTrustDetails"
                  class="od-trans-trust-details"
                  v-model="internalValue.trustItems"
                  :depositAmount="internalValue.amount"
                  @forteResponse="forteResponse"
                />
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn id="odTransDepositTicket"
        class="odtrans depositticket"
        text color="blue darken-1"
        v-if="internalValue.type === 'DEPOSIT' || internalValue.type === 'UNREGISTERED'"
        @click="printReceipt('depositticket')"
      >
        <v-icon>{{icons.mdiPrinter}}</v-icon>
        Print (Deposit Ticket)
      </v-btn>
      <v-btn v-if="isTrustReceipt"
        class="odtrans trustreceipt"
        id="odTransTrustReceipt"
        text color="blue darken-1"
        @click="printReceipt('trustreceipt')"
      >
        <v-icon>{{icons.mdiPrinter}}</v-icon>
        Print (Trust Receipt)
      </v-btn>
      <v-spacer></v-spacer>
      <AuditDialog
        :identifier="internalValue.id"
        activatorCaption="Audit History"
      />
      <UploadFile id="odTransUploadFile"
        class="odtrans uploadFile"
        bucketName="OD"
        program="OD"
        parentObjectType="ODTransaction"
        :parentObjectId="internalValue.id"
        :itemIsSaved="!isNewItem">
        <template v-slot:activator="{ activatorProps }">
          <v-btn
            color="primary"
            text
            @contextmenu.prevent="imagesRightClick(activatorProps.rightClick)"
            @click="imagesLeftClick(activatorProps.leftClick)"
          >
            {{activatorProps.caption}}
          </v-btn>
        </template>
      </UploadFile>
      <v-btn class="odtrans close"
        id="odTransClose"
        text color="blue darken-1"
        @click="close"
      >
        Cancel
      </v-btn>
      <v-btn class="odtrans save"
        id="odTransSave"
        text color="blue darken-1"
        @click="save"
      >
        Save
      </v-btn>
      <div v-if="isNewItem">(Shift+Enter to Duplicate)</div>
    </v-card-actions>
  </v-card>
</template>
<script>
import {
  mapState,
  mapActions,
  mapGetters,
  mapMutations,
} from 'vuex';
import DepositVoucherMixin from './DepositVoucher.mixin';
import UploadFile from '../common/UploadFile.vue';
import CashCheckDetails from './CashCheckDetails.vue';
import CreditDetails from './CreditDetails.vue';
import EFTDetails from './EFTDetails.vue';
import TrustDetails from './TrustDetails.vue';
import ClarionDateControl from '../common/ClarionDateControl.vue';
import AuditDialog from '../common/audit/AuditDialog.vue';
import { fields } from './DepositVoucher.shared';
import { clients } from '../../util/clients';
import { isNotBeforeDate } from '../../util/shared/tmc-global';

const { backendRest } = clients.direct;

export default {
  name: 'DepositDetails',
  mixins: [DepositVoucherMixin],
  components: {
    CashCheckDetails,
    CreditDetails,
    EFTDetails,
    TrustDetails,
    ClarionDateControl,
    UploadFile,
    AuditDialog,
  },
  data: () => ({
    fiscalYear: 0,
    componentKey: 0,
  }),
  props: {
    editedItem: {
      type: Object,
      default: () => ({
        ...fields(),
      }),
    },
  },
  mounted() {
    /* cleanup old forte config */
    this.setConfigData({});
  },
  computed: {
    internalValue: {
      get() {
        return this.editedItem;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    clearableDepositTypes() {
      return this.depositTypes === undefined ? undefined : [{ name: '', description: '(none)' }, ...this.depositTypes];
    },
    ...mapState({
      depositTypes: (state) => state.enums.ODDepositEnum,
      odSettingItem: (state) => state.SystemConfig.odSettingItem,
    }),
    ...mapGetters('user', [
      'getSettings',
    ]),
    remarkCaption() {
      return this.internalValue.depositType === 'TRUST' ? 'Received Of' : 'Remarks';
    },
  },
  methods: {
    ...mapActions([
      'reAuth',
    ]),
    ...mapMutations('ForteService', [
      'setConfigData',
    ]),
    async printReceipt(receiptType) {
      const { jwt } = await this.reAuth();
      const saveTrans = isNotBeforeDate(
        this.internalValue.register,
        this.todaysDate,
        (this.durationOfCorrection + 1),
      );
      let isReceiptPrintable;
      if (saveTrans || this.isNewItem) {
        isReceiptPrintable = await this.internalSave(undefined, false);
      } else {
        isReceiptPrintable = true;
      }
      if (isReceiptPrintable) {
        const { id } = this.internalValue;
        const url = `${backendRest.defaults.baseURL}/${receiptType}?odTransId=${id}&token=${jwt}`;
        window.open(url, '_blank');
      }
    },
    forteResponse(response) {
      this.internalValue.remarks += response;
    },
    async updateForteConfig() {
      if (
        this.unregisteredOrDeposit
        && this.internalValue.depositType === 'CREDIT'
      ) {
        // call forte event
        await this.$store.dispatch('ForteService/fetchForteConfig', {
          scope: 'odconfig',
          forteAmount: this.internalValue.amount.toString(),
        });
        this.componentKey += 1;
      }
      if (
        this.unregisteredOrDeposit
        && this.internalValue.depositType === 'CASHCHECK'
      ) {
        this.componentKey += 1;
      }
    },
    isItemCleared(value) {
      this.internalValue.creditItems = this.internalValue.creditItems.map((element) => {
        const creditItem = { ...element };
        creditItem.isCleared = value;
        return creditItem;
      });
    },
    onStatusChange() {
      if (this.internalValue.depositType === 'CREDIT') {
        if (this.internalValue.status === 'PAID') {
          return this.isItemCleared(true);
        }
        return this.isItemCleared(false);
      }
      return false;
    },
    triggerValidation() {
      const ref = this.$refs.form.validate();
      return ref;
    },
  },
};
</script>
