<template>
  <v-data-table
    dense
    :headers="headers"
    :items="internalValue"
    :items-per-page="-1"
    hide-default-footer
    class="elevation-1 CashCheckDetails"
  >
    <template v-slot:top>
      <div class="d-flex justify-end">
        <v-btn
          class="mx-2" color="primary"
          fab icon x-small
          @click="addOneAndFocusIfFirst"
          v-if="canAdd"
        >
          <v-icon>
            {{icons.mdiPlus}}
          </v-icon>
        </v-btn>
      </div>
    </template>

    <template v-slot:[`item.description`]="{ item }">
      <v-text-field class="od-cashcheck-edit-desc-text"
        dense
        v-model="item.description"
        :rules="descriptionValidation"
        label="Edit Description"
        single-line
        readonly
      ></v-text-field>
    </template>

    <template v-slot:[`item.amount`]="{ item }">
      <v-text-field
        dense
        v-model="item.amount"
        :rules="amountValidation"
        single-line
        class="cash-check-amount"
        @blur="somethingChanged"
        @keydown="shortcutHandler($event, item)"
        @keydown.enter="somethingChanged"
        @focus="focusAmount"
      ></v-text-field>
    </template>

    <template
      v-if="odSettingItem.hideDrawnBy !== true"
      v-slot:[`item.drawnBy`]="{ item }"
    >
      <v-text-field
        dense
        v-model="item.drawnBy"
        single-line
        class="cash-check-drawn-by"
        @keydown="shortcutHandler($event, item, true)"
        @focus="focusAmount"
        @blur="somethingChanged"
      ></v-text-field>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small class="mr-2 od-cash-check-delete-icon"
        tabindex="-1"
        @click="removeOne(item)"
      >
        {{icons.mdiDelete}}
      </v-icon>
    </template>

    <template slot="body.append">
      <tr class="blue--text">
          <td colspan="1" id="cash-check-remaining-amount">
            Total Remaining : ${{ amountFormat(amountRemaining) }}
          </td>
      </tr>
      <tr class="blue--text">
          <td colspan="1" id="total-check-count">
            Check Count : {{ checkCount }}
          </td>
      </tr>
    </template>

  </v-data-table>
</template>

<script>
import {
  mapState,
} from 'vuex';

import {
  mdiPlus,
  mdiDelete,
} from '@mdi/js';

import {
  isAltC,
  isTabOrPlus,
  focusBySelector,
  focusAmount,
  amountToNumber,
  amountFormat,
  selectInputTextBySelector,
} from '../../util/shared/vue-global';

const defaultItem = {
  description: 'check',
  amount: 0,
  drawnBy: '',
};

export default {
  name: 'CashCheckDetails',
  created() {
    this.$store.dispatch('SystemConfig/loadConfig', { requiredScope: 'odconfig' });
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    depositAmount: [Number, String],
  },
  data: () => ({
    icons: {
      mdiPlus,
      mdiDelete,
    },
    defaultHeaders: [
      { text: 'Description (alt+c to Cash/Check)', value: 'description' },
      { text: 'Amount', value: 'amount' },
      { text: 'Drawn by', value: 'drawnBy' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
  }),
  computed: {
    ...mapState({
      odSettingItem: (state) => state.SystemConfig.odSettingItem || {},
    }),
    internalValue: {
      get() {
        const newVal = JSON.parse(JSON.stringify(this.value));
        const updatedValue = newVal.map(this.loadInitialValues);
        return updatedValue;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    descriptionValidation() {
      return [
        (value) => !!value || 'Required',
        (value) => {
          if (value === 'cash') {
            const howMany = this.internalValue.filter((item) => item.description === value);
            return howMany.length < 2 || 'Duplicate cash entries';
          }
          return true;
        },
      ];
    },
    amountValidation() {
      return [
        (value) => !!value || 'Required',
        (value) => value !== 0 || 'Should be non-zero',
      ];
    },
    checkCount() {
      const howMany = this.internalValue.filter((item) => item.description === 'check');
      return howMany.length;
    },
    amountRemaining() {
      let totalRemainingAmount = amountToNumber(this.depositAmount);
      for (let i = 0; i < this.internalValue.length; i += 1) {
        totalRemainingAmount -= amountToNumber(this.internalValue[i].amount);
      }
      return totalRemainingAmount;
    },
    headers() {
      if (this.odSettingItem.hideDrawnBy === true) {
        return this.defaultHeaders.filter((h) => h.value !== 'drawnBy');
      }
      return this.defaultHeaders;
    },
    canAdd() {
      return amountToNumber(this.amountRemaining) > 0 || this.internalValue.length === 0;
    },
  },
  methods: {
    amountToNumber,
    amountFormat,
    loadInitialValues(item) {
      return {
        ...item,
        amount: amountFormat(amountToNumber(item.amount)),
      };
    },
    shortcutHandler(e, item, isAddNew = false) {
      if (isAltC(e)) {
        e.preventDefault();
        let itemIndex;
        this.internalValue.forEach((obj, i) => {
          if (
            obj.description === item.description
            && amountToNumber(obj.amount) === amountToNumber(item.amount)
          ) {
            itemIndex = i;
          }
        });
        const itemDescription = this.internalValue[itemIndex].description;
        this.internalValue[itemIndex].description = (itemDescription === 'check') ? 'cash' : 'check';
        this.somethingChanged();
      }
      // when Drawn by will hide then amount field will trigger add new
      let addNewItem = isAddNew;
      if (this.odSettingItem.hideDrawnBy === true) {
        addNewItem = true;
      }
      if (addNewItem && isTabOrPlus(e)) {
        const allAmounts = document.querySelectorAll('.cash-check-amount input');
        let allLastColumns = [...allAmounts];
        if (this.odSettingItem.hideDrawnBy !== true) {
          allLastColumns = document.querySelectorAll('.cash-check-drawn-by input');
        }
        if (allAmounts.length > 0) {
          if (allLastColumns[allLastColumns.length - 1].id === e.target.id) {
            e.preventDefault();
            let totalAmount = 0;
            allAmounts.forEach((v) => {
              totalAmount += amountToNumber(v.value);
              this.somethingChanged();
            });
            if ((amountToNumber(this.depositAmount) > totalAmount)) {
              this.somethingChanged();
              this.addOne();
              this.$nextTick(() => {
                focusBySelector('.cash-check-amount input', 'last');
                selectInputTextBySelector('.cash-check-amount input', 'last');
              });
            }
          }
        }
      }
    },
    focusAmount,
    addOneAndFocusIfFirst() {
      this.addOne();
      this.$nextTick(() => {
        const allAmounts = document.querySelectorAll('.cash-check-amount input');
        if (allAmounts.length === 1) {
          focusBySelector('.cash-check-amount input', 'last');
          selectInputTextBySelector('.cash-check-amount input', 'last');
        }
      });
    },
    somethingChanged() {
      this.$emit('input', this.internalValue);
      this.$emit('triggerValidation');
    },
    addOne() {
      const allAmounts = document.querySelectorAll('.cash-check-amount input');
      const lastAmount = allAmounts[allAmounts.length - 1]
        ? allAmounts[allAmounts.length - 1].value : 0;
      this.$emit('input', [
        ...this.internalValue,
        {
          ...defaultItem,
          amount: amountFormat(amountToNumber(lastAmount)),
        },
      ]);
      return true;
    },
    removeOne(item) {
      // const indexOfRemove = this.internalValue.indexOf(item);
      this.$emit('input', [
        ...this.internalValue.filter((candidate) => item !== candidate),
      ]);
    },
  },
};
</script>

<style lang="sass">
  .cash-check-amount input
    text-align: right
</style>
