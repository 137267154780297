<template>
  <div class="Address pa-4">
    <span>{{caption}}</span>
    <v-row no-gutters>
      <v-col>
        <v-text-field class="my-n2 py-n2"
          id="tmcAddress1Text"
          :value="address.address1"
          @input="emitAddress({ address1: $event })"
          label="Address 1"
          type="string"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-text-field class="my-n2 py-n2"
          id="tmcAddress2Text"
          :value="address.address2"
          @input="emitAddress({ address2: $event })"
          label="Address 2"
          type="string"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="mr-2">
        <v-text-field class="my-n2 py-n2"
          id="tmcAddressCityText"
          :value="address.city"
          @input="emitAddress({ city: $event })"
          label="City"
          type="string"
        ></v-text-field>
      </v-col>
      <v-col class="mr-2">
        <v-select class="pt-2 my-0"
          id="tmcAddressStateText"
          :value="(address.state || '').toUpperCase()"
          @input="emitAddress({ state: $event })"
          :items="states"
          item-text="abbreviation"
          item-value="abbreviation"
          label="State"
          dense
        ></v-select>
      </v-col>
      <v-col>
        <v-text-field class="my-n2 py-n2"
          id="tmcAddressZipText"
          :value="address.zip"
          @input="emitAddress({ zip: $event })"
          :rules="zipValidation"
          @keydown="checkZipKeydown"
          label="Zip"
          type="string"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Address',
  props: {
    address: Object,
    caption: {
      type: String,
      default: 'Address:',
    },
  },
  computed: {
    zipValidation() {
      return [
        (value) => {
          const len = (value || '').length;
          if (len > 0) {
            return (len >= 5 && len <= 10) || 'Invalid format (expected 12345 or 12345-1234 or K9V 4R6)';
          }
          return true;
        },
        (value) => {
          const theValue = (value || '');
          if (theValue !== '') {
            const matches = theValue.match(/[-0-9a-zA-Z ]+/);
            return (matches !== undefined) || 'Invalid format (expected 12345 or 12345-1234 or K9V 4R6)';
          }
          return true;
        },
      ];
    },
    states() {
      return [
        { name: 'ALBERTA', abbreviation: 'AB' },
        { name: 'ALABAMA', abbreviation: 'AL' },
        { name: 'ALASKA', abbreviation: 'AK' },
        { name: 'AMERICAN SAMOA', abbreviation: 'AS' },
        { name: 'ARIZONA', abbreviation: 'AZ' },
        { name: 'ARKANSAS', abbreviation: 'AR' },
        { name: 'BRITISH COLUMBIA', abbreviation: 'BC' },
        { name: 'CALIFORNIA', abbreviation: 'CA' },
        { name: 'COLORADO', abbreviation: 'CO' },
        { name: 'CONNECTICUT', abbreviation: 'CT' },
        { name: 'DELAWARE', abbreviation: 'DE' },
        { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC' },
        { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM' },
        { name: 'FLORIDA', abbreviation: 'FL' },
        { name: 'GEORGIA', abbreviation: 'GA' },
        { name: 'GUAM', abbreviation: 'GU' },
        { name: 'HAWAII', abbreviation: 'HI' },
        { name: 'IDAHO', abbreviation: 'ID' },
        { name: 'ILLINOIS', abbreviation: 'IL' },
        { name: 'INDIANA', abbreviation: 'IN' },
        { name: 'IOWA', abbreviation: 'IA' },
        { name: 'KANSAS', abbreviation: 'KS' },
        { name: 'KENTUCKY', abbreviation: 'KY' },
        { name: 'LOUISIANA', abbreviation: 'LA' },
        { name: 'MANITOBA', abbreviation: 'MB' },
        { name: 'MAINE', abbreviation: 'ME' },
        { name: 'MARSHALL ISLANDS', abbreviation: 'MH' },
        { name: 'MARYLAND', abbreviation: 'MD' },
        { name: 'MASSACHUSETTS', abbreviation: 'MA' },
        { name: 'MICHIGAN', abbreviation: 'MI' },
        { name: 'MINNESOTA', abbreviation: 'MN' },
        { name: 'MISSISSIPPI', abbreviation: 'MS' },
        { name: 'MISSOURI', abbreviation: 'MO' },
        { name: 'MONTANA', abbreviation: 'MT' },
        { name: 'NEW BRUNSWICK', abbreviation: 'NB' },
        { name: 'NEBRASKA', abbreviation: 'NE' },
        { name: 'NEWFOUNDLAND AND LABRADOR', abbreviation: 'NL' },
        { name: 'NOVA SCOTIA', abbreviation: 'NS' },
        { name: 'NORTHWEST TERRITORIES', abbreviation: 'NT' },
        { name: 'NUNAVUT', abbreviation: 'NU' },
        { name: 'NEVADA', abbreviation: 'NV' },
        { name: 'NEW HAMPSHIRE', abbreviation: 'NH' },
        { name: 'NEW JERSEY', abbreviation: 'NJ' },
        { name: 'NEW MEXICO', abbreviation: 'NM' },
        { name: 'NEW YORK', abbreviation: 'NY' },
        { name: 'NORTH CAROLINA', abbreviation: 'NC' },
        { name: 'NORTH DAKOTA', abbreviation: 'ND' },
        { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP' },
        { name: 'OHIO', abbreviation: 'OH' },
        { name: 'OKLAHOMA', abbreviation: 'OK' },
        { name: 'ONTARIO', abbreviation: 'ON' },
        { name: 'OREGON', abbreviation: 'OR' },
        { name: 'PRINCE EDWARD ISLAND', abbreviation: 'PE' },
        { name: 'PALAU', abbreviation: 'PW' },
        { name: 'PENNSYLVANIA', abbreviation: 'PA' },
        { name: 'QUEBEC', abbreviation: 'QC' },
        { name: 'PUERTO RICO', abbreviation: 'PR' },
        { name: 'RHODE ISLAND', abbreviation: 'RI' },
        { name: 'SOUTH CAROLINA', abbreviation: 'SC' },
        { name: 'SOUTH DAKOTA', abbreviation: 'SD' },
        { name: 'SASKATCHEWAN', abbreviation: 'SK' },
        { name: 'TENNESSEE', abbreviation: 'TN' },
        { name: 'TEXAS', abbreviation: 'TX' },
        { name: 'UTAH', abbreviation: 'UT' },
        { name: 'VERMONT', abbreviation: 'VT' },
        { name: 'VIRGIN ISLANDS', abbreviation: 'VI' },
        { name: 'VIRGINIA', abbreviation: 'VA' },
        { name: 'WASHINGTON', abbreviation: 'WA' },
        { name: 'WEST VIRGINIA', abbreviation: 'WV' },
        { name: 'WISCONSIN', abbreviation: 'WI' },
        { name: 'WYOMING', abbreviation: 'WY' },
        { name: 'YUKON', abbreviation: 'YT' },
      ];
    },
  },
  methods: {
    emitAddress(updates) {
      this.$emit('update:address', {
        ...this.address,
        ...updates,
      });
    },
    checkZipKeydown(e) {
      if ((/[-0-9a-zA-Z ]+/.test(e.key))
        || (e.key === 'a' && (e.ctrlKey || e.metaKey))
        || (e.keyCode === 8)
        || (e.keyCode === 9)) {
        // allow 0-9, -, backspace, Ctrl+a, alphabets, space
      } else {
        e.preventDefault();
        console.warn(`blocked ${e.keyCode} ${e.key}`);
      }
    },
  },
};
</script>
