<template>
  <div :key="reloadKey">
    <v-row>
      <v-col cols="2">
        <v-select id="tmc-criteria-type"
          class="tmc-criteria-type"
          v-model="criteria.type"
          :items="filteredTransTypes"
          item-text="description"
          item-value="name"
          label="By transaction type"
          @change="searchByCriteria(); disableAutoComplete();"
        ></v-select>
      </v-col>
      <v-col>
        <v-select id="tmc-od-criteria-dept"
          class="tmc-od-criteria-dept"
          v-model="criteria.department"
          :items="clearableDepartments"
          item-text="caption"
          item-value="id"
          label="By department"
          @change="this.searchByCriteria"
        ></v-select>
      </v-col>
      <v-col v-if="unregisteredOrDeposit">
        <v-select id="odByDepositSelect"
          class="od-by-deposit-select"
          v-model="criteria.depositType"
          :items="clearableDepositTypes"
          item-text="description"
          item-value="name"
          label="By deposit type"
          @change="this.searchByCriteria"
          multiple
        >
          <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.description }}</span>
              </v-chip>
              <span
                v-if="index === 1"
                class="grey--text text-caption"
              >
                (+{{ criteria.depositType.length - 1 }} others)
              </span>
            </template>
            <template v-slot:prepend-item>
              <v-list-item
                ripple
                @click="toggle"
              >
                <v-list-item-action>
                  <v-icon :color="criteria.depositType.length > 0 ? 'indigo darken-4' : ''">
                    {{ icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    Select All
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
        </v-select>
      </v-col>
      <v-col cols="2">
        <!-- todo - fy select -->
        <FySelect id="odFiscalYearSelect"
          class="od-fy-select"
          :fyRange="odStatsFY"
          v-model="criteria.fiscalYear"
          @input="this.searchByCriteria" />
      </v-col>
      <v-col cols="2">
        <v-select id="odStatusSelect"
          class="od-status-select"
          v-model="criteria.status"
          :items="statuses"
          item-text="description"
          item-value="name"
          label="Status"
          @change="this.searchByCriteria"
        ></v-select>
      </v-col>
      <v-col cols="2" v-if="criteria.type === 'VOUCHER'">
          <v-select id="odStatusPrintedSelect"
            class="od-status-printed-select"
            v-model="criteria.hasPrinted"
            :items="clearablePrintOptions"
            item-text="caption"
            item-value="value"
            label="Printed?"
            @change="this.searchByCriteria"
          ></v-select>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="8">
          <v-container fluid>
            <v-row>
              <ClarionDateControl classes="tmc-odtrans-reg-from"
                id="odTransRegFromIssuedDate"
                v-model="criteria.registerDate.from"
                :isRequired="false" @blurred="this.searchByCriteria" :label="`From (Issued)`" />
              <ClarionDateControl classes="tmc-odtrans-reg-to"
                id="odTransRegToDate"
                v-model="criteria.registerDate.to"
                :isRequired="false" @blurred="this.searchByCriteria" :label="`To`" />
              <ClarionDateControl classes="tmc-odtrans-final-from"
                id="odTransFinalFromPaidDate"
                v-model="criteria.finalizedDate.from"
                :isRequired="false" @blurred="this.searchByCriteria" :label="`From (Paid)`" />
              <ClarionDateControl classes="tmc-odtrans-final-to"
                id="odTransFinalToDate"
                v-model="criteria.finalizedDate.to"
                :isRequired="false" @blurred="this.searchByCriteria" :label="`To`" />
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="4">
          <!-- Some browser honor autocomplete="off". Some are matching by the name/id properties.
          Giving wildcard a special name should help avoid Chrome autofilling it. -->
          <v-text-field ref="wildcard"
            v-model="criteria.wildcard"
            id="odwildcard"
            class="od-wildcard-text"
            name="odwildcard"
            label="Wildcard search (what, whom, remarks, amount, reg # or voucher #)"
            @input="wildcardSearchDelayed"
            autocomplete="off"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-btn color="primary"
            rounded
            @click="resetCriteria">
            <v-icon class="mr-2">{{icons.mdiReload}}</v-icon>
            Reset
          </v-btn>
        </v-col>
    </v-row>
  </div>
</template>

<script>

import {
  mapState,
  mapMutations,
  mapGetters,
  mapActions,
} from 'vuex';
import {
  mdiReload,
  mdiCloseBox,
  mdiCheckboxBlankOutline,
  mdiMinusBox,
} from '@mdi/js';
import { headersByType } from './transaction.headers';
import ClarionDateControl from '../common/ClarionDateControl.vue';
import FySelect from '../common/FySelect.vue';
import {
  toIsoDate,
  getFiscalYears,
} from '../../util/shared/tmc-global';

const initData = () => ({
  reloadKey: 0,
  icons: {
    mdiReload,
    mdiCloseBox,
    mdiCheckboxBlankOutline,
    mdiMinusBox,
  },
  criteria: {
    type: undefined,
    depositType: [],
    status: undefined,
    department: undefined,
    // todo for finalized date also
    registerDate: {
      from: '',
      to: '',
    },
    finalizedDate: {
      from: '',
      to: '',
    },
    fiscalYear: 0,
    wildcard: undefined,
    hasPrinted: undefined,
    sortBy: [
      {
        column: 'registration',
        direction: 'desc',
      },
    ],
    skip: 0,
    limit: undefined,
  },
});

export default {
  name: 'TransactionCriteria',
  components: {
    ClarionDateControl,
    FySelect,
  },
  data: () => initData(),
  props: {
    defaultType: {
      type: String,
      default: 'DEPOSIT',
    },
    chunkID: {
      type: String,
      default: null,
    },
  },
  created() {
    this.initCriteria();
    this.loadFiscalYears();
    this.searchByCriteria();
    this.selectAllDespositTypes();
  },
  mounted() {
    this.focusWildcard();
    this.disableAutoComplete();
  },
  methods: {
    ...mapActions('OD', [
      'loadFiscalYears',
    ]),
    ...mapMutations('OD', [
      'setHeaders',
      'setCriteria',
      'setTransactionType',
    ]),
    searchByCriteria() {
      const type = this.criteria.type || 'DEPOSIT';
      this.setHeaders(headersByType[type]);
      this.setTransactionType(type);

      if (this.criteria.depositType.length === 0) {
        this.criteria.depositType = [];
      }
      if (this.criteria.department === '') {
        this.criteria.department = undefined;
      }
      this.setCriteria(this.mappedCriteria);
      this.$store.dispatch('OD/searchTransactions');
      // When transaction criteria has changed, need to reset all selected items.
      this.$emit('resetValues');
    },
    wildcardSearchDelayed() {
      // cancel pending call
      clearTimeout(this.delayTimerId);

      // delay new call
      this.delayTimerId = setTimeout(async () => {
        this.setCriteria(this.mappedCriteria);
        await this.$store.dispatch('OD/searchTransactions');
      }, 250);
    },
    resetCriteria() {
      // ☢️ nuclear option - full page reload to reset all components
      window.location.reload();
    },
    focusWildcard() {
      if (this.$refs.wildcard) {
        this.$refs.wildcard.focus();
      }
    },
    checkTransType(chunkID) {
      let transType = null;
      const result = (chunkID || '').match(new RegExp('(voucher)|(deposit)', 'gi'));
      if (result) {
        const resultVal = result[0];
        transType = resultVal.toUpperCase();
      }
      return transType;
    },
    initCriteria() {
      if (this.chunkID) {
        const transTypeResponse = this.checkTransType(this.chunkID);
        const transactionType = transTypeResponse || this.defaultType;
        this.setHeaders(headersByType[transactionType]);
        this.criteria.type = transactionType;
        this.criteria.wildcard = this.chunkID;
        this.criteria.fiscalYear = 0;
      } else {
        // defaults
        this.setHeaders(headersByType[this.defaultType]);
        this.criteria.type = this.defaultType;
        const fys = getFiscalYears(new Date(), new Date(), false);
        if (fys && fys[0] && fys[0].year) {
          this.criteria.fiscalYear = fys[0].year;
        }
      }

      const { itemsperpage } = this.getSettings;
      this.criteria.skip = 0;
      this.criteria.limit = itemsperpage;

      this.focusWildcard();
    },
    // https://github.com/aacassandra/vue-disable-autocomplete/blob/master/dist/vue-disable-autocomplete.js
    disableAutoComplete() {
      const elements = document.querySelectorAll('[autocomplete="off"]');

      if (!elements) {
        return;
      }

      elements.forEach((element) => {
        element.setAttribute('readonly', 'readonly');
        element.style.backgroundColor = 'inherit'; // eslint-disable-line no-param-reassign

        setTimeout(() => {
          element.removeAttribute('readonly');
          this.focusWildcard();
        }, 500);
      });
    },
    selectAllDespositTypes() {
      if (this.depositTypes === undefined) {
        this.criteria.depositType = [];
      } else {
        this.criteria.depositType = [...this.depositTypes.map((d) => d.name)];
      }
      return this.criteria.depositType;
    },
    toggle() {
      this.$nextTick(() => {
        if (this.isAllDepositTypeSelected) {
          this.criteria.depositType = [];
        } else {
          this.selectAllDespositTypes();
          this.searchByCriteria();
        }
      });
    },
  },
  computed: {
    ...mapState('OD', [
      'odStatsFY',
      'headers',
      'transactions',
      'departments',
      'whats',
      'whoms',
      'nextVoucherNumber',
      'nextRegistrationNumber',
    ]),
    ...mapState({
      transTypes: (state) => state.enums.ODTransType,
      depositTypes: (state) => state.enums.ODDepositEnum,
      statuses(state) {
        return state.enums.ODStatus === undefined ? undefined : [{
          description: '',
          name: '',
        }, ...state.enums.ODStatus];
      },
      odSettingItem: (state) => state.SystemConfig.odSettingItem,
    }),
    ...mapGetters('user', [
      'getSettings',
    ]),
    filteredTransTypes() {
      if (!this.transTypes) {
        return [];
      }
      return this.transTypes.filter((t) => t.name !== 'UNREGISTERED'
        || this.odSettingItem.unregisteredDep);
    },
    unregisteredOrDeposit() {
      return this.criteria.type === 'DEPOSIT'
        || this.criteria.type === 'UNREGISTERED';
    },
    clearablePrintOptions() {
      return [
        {
          value: null,
          caption: '(any)',
        },
        {
          value: 0,
          caption: 'No',
        },
        {
          value: 1,
          caption: 'Yes',
        },
      ];
    },
    clearableDepositTypes() {
      return this.depositTypes === undefined ? undefined : [...this.depositTypes];
    },
    clearableDepartments() {
      return this.departments === undefined ? undefined : [{
        id: '',
        dept: 0,
        description: '',
        caption: '',
      }, ...this.departments];
    },
    isAllDepositTypeSelected() {
      if (this.depositTypes === undefined) {
        return false;
      }
      return this.criteria.depositType.length === this.depositTypes.length;
    },
    isSomeDepositTypeSelected() {
      return this.criteria.depositType.length > 0 && !this.isAllDepositTypeSelected;
    },
    icon() {
      if (this.isAllDepositTypeSelected) return this.icons.mdiCloseBox;
      if (this.isSomeDepositTypeSelected) return this.icons.mdiMinusBox;
      return this.icons.mdiCheckboxBlankOutline;
    },
    mappedCriteria() {
      // graphql-friendly mapping
      const types = this.criteria.type ? [this.criteria.type] : undefined;
      const departments = this.criteria.department ? [this.criteria.department] : undefined;
      const status = this.criteria.status ? this.criteria.status : undefined;
      const depositType = this.criteria.depositType.length !== 0
        ? this.criteria.depositType : undefined;
      let fromDate;
      if (this.criteria.registerDate.from && this.criteria.registerDate.from !== '') {
        fromDate = toIsoDate(this.criteria.registerDate.from);
      }
      let toDate;
      if (this.criteria.registerDate.to && this.criteria.registerDate.to !== '') {
        toDate = toIsoDate(this.criteria.registerDate.to);
      }

      let fromFinalizedDate;
      if (this.criteria.finalizedDate.from && this.criteria.finalizedDate.from !== '') {
        fromFinalizedDate = toIsoDate(this.criteria.finalizedDate.from);
      }
      let toFinalizedDate;
      if (this.criteria.finalizedDate.to && this.criteria.finalizedDate.to !== '') {
        toFinalizedDate = toIsoDate(this.criteria.finalizedDate.to);
      }

      let fiscalYear;
      if (this.criteria.fiscalYear && this.criteria.fiscalYear > 0) {
        fiscalYear = this.criteria.fiscalYear;
      }

      const {
        wildcard,
        sortBy,
        hasPrinted,
        skip,
        limit,
      } = this.criteria;
      const result = {
        types,
        depositType,
        status,
        departments,
        fromDate,
        toDate,
        fromFinalizedDate,
        toFinalizedDate,
        fiscalYear,
        wildcard,
        sortBy,
        hasPrinted,
        skip,
        limit,
      };
      return result;
    },
  },
};
</script>
