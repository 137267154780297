<template>
  <v-container>
    <h3>Deposits &amp; Vouchers</h3>
    <TransactionList
      :canAdd="true" :canEdit="true" :canDelete="true"
      defaultType="DEPOSIT" />
  </v-container>
</template>

<script>

import TransactionList from '@/components/OD/TransactionList.vue';

export default {
  name: 'Deposits',
  components: {
    TransactionList,
  },
};
</script>
