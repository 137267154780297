var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"TransactionList",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('h5',[_vm._v("Filter by")])])],1),_c('TransactionCriteria',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPrintScreen),expression:"!isPrintScreen"}],attrs:{"defaultType":_vm.defaultType,"propHeaders":_vm.headers,"chunkID":_vm.chunkID},on:{"resetValues":_vm.resetValues}}),_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-data-table',{staticClass:"elevation-1 mt-2 tmc-odtrans-dt",attrs:{"headers":_vm.headers,"items":_vm.transactions,"options":_vm.options,"items-per-page":_vm.getSettings.itemsperpage,"server-items-length":_vm.getSettings.serverItemsLength,"footer-props":_vm.getSettings.footerProps,"loading":_vm.loading,"loading-text":_vm.loadingText,"show-select":"","dense":"","item-class":_vm.dataItem},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){return _vm.$set(_vm.getSettings, "itemsperpage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.getSettings, "itemsperpage", $event)},"update:page":_vm.handlePage},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-toolbar',{staticClass:"mr-0 pr-0",attrs:{"flat":""}},[_c('v-spacer'),_c('div',[_c('v-data-footer',_vm._b({attrs:{"pagination":pagination,"options":options,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText","items-per-page":_vm.getSettings.itemsperpage,"server-items-length":_vm.getSettings.serverItemsLength},on:{"update:options":updateOptions,"update:itemsPerPage":function($event){return _vm.$set(_vm.getSettings, "itemsperpage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.getSettings, "itemsperpage", $event)}}},'v-data-footer',_vm.getSettings.footerProps,false))],1)],1),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{staticClass:"mt-6 shrink",attrs:{"id":"od-trans-select-mark-date","label":"Select Date to Mark","type":"date"},model:{value:(_vm.markingDateForItems),callback:function ($$v) {_vm.markingDateForItems=$$v},expression:"markingDateForItems"}}),_c('div',[(_vm.typesSelected.includes('UNREGISTERED'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"primary","fab":"","icon":"","small":"","disabled":!_vm.canMarkItemsPaidCancel},on:{"click":_vm.assignRegNums}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiAlphaRCircle)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Assign Registration Numbers")])]):_vm._e()],1),_c('div',[(_vm.bulkActionDeposit)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"primary","id":"bulk-mark-elect-deposit-paid","fab":"","icon":"","small":"","disabled":!_vm.canMarkItemsPaidCancel},on:{"click":_vm.bulkMarkPaid}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiAlphaPCircle)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Mark Paid")])]):_vm._e()],1),_c('div',[(_vm.typesSelected.includes('VOUCHER'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"id":"voucher-bulk-mark-paid","color":"primary","fab":"","icon":"","small":"","disabled":!_vm.canMarkItemsPaidCancel},on:{"click":function($event){return _vm.itemsBulkUpdate('PAID')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiAlphaPCircle)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Mark Paid")])]):_vm._e()],1),_c('div',[(_vm.typesSelected.includes('VOUCHER'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"id":"od-trans-cancelled","color":"primary","fab":"","icon":"","small":"","disabled":!_vm.canMarkItemsPaidCancel},on:{"click":_vm.markItemsCancelledInBulk}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiAlphaCCircle)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Mark Cancelled")])]):_vm._e()],1),_c('div',[(_vm.typesSelected.includes('VOUCHER') || _vm.bulkActionDeposit)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"primary","id":"mark-trans-unpaid","fab":"","icon":"","small":"","disabled":!_vm.canMarkItemsUnpay || !['VOUCHER', 'DEPOSIT'].includes(_vm.currentTransactionType)},on:{"click":_vm.markUnpaid}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiAlphaUCircle)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.unpaidCaption))])]):_vm._e()],1),_c('div',[(_vm.typesSelected.includes('VOUCHER'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"id":"od-trans-void","color":"primary","fab":"","icon":"","small":"","disabled":!_vm.canMarkItemsPaidCancel},on:{"click":_vm.markItemsVoidInBulk}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiAlphaVCircle)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Mark Void")])]):_vm._e()],1),_c('div',[(_vm.typesSelected.includes('DEPOSIT') && _vm.isUnregisteredDepositAllowed)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"id":"odTransBackBurgerIcon","color":"primary","fab":"","icon":"","small":"","disabled":!_vm.canMarkItemsPaidCancel},on:{"click":_vm.assignUnregNums}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiBackburger)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Unregister Deposit")])]):_vm._e()],1),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),(_vm.canDelete)?_c('v-icon',{staticClass:"mx-2",attrs:{"id":"odTransDeleteIcon","color":"primary","fab":"","icon":"","small":"","disabled":_vm.selected.length === 0},on:{"click":_vm.deleteItems}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")]):_vm._e(),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('div',[(_vm.typesSelected.includes('VOUCHER'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"id":"odTransPrintVoucherCheckstBtn","color":"primary","fab":"","icon":"","small":"","disabled":!_vm.vouchersSelected},on:{"click":_vm.vouchersBulkPrint}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPrinter)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Print voucher checks")])]):_vm._e()],1),_c('div',[(_vm.unregisteredOrDeposit)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"id":"odTransPrintDepositTicketBtn","color":"primary","fab":"","icon":"","small":"","disabled":!_vm.unregisteredOrDepositsSelected},on:{"click":_vm.depositTicketsBulkPrint}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPrinter)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Print Deposit Ticket")])]):_vm._e()],1),_c('div',[(_vm.onlyTrustReceiptsSelected)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"id":"odTransPrintTrustReceiptBtn","color":"primary","fab":"","icon":"","small":"","disabled":!_vm.onlyTrustReceiptsSelected},on:{"click":_vm.trustReceiptBulkPrint}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPrinter)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Print Trust Receipt")])]):_vm._e()],1),_c('v-spacer'),_c('TransactionNewEditDialog',{attrs:{"canAdd":_vm.canAdd,"selectedProp":_vm.selected}})],1)]}},{key:"item.type",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('td',{staticClass:"text-start clickable",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.shorterType(value))+" ")])]}},{key:"item.hasFiles",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('td',{staticClass:"text-start clickable"},[(value)?_c('span',[_c('UploadFile',{staticClass:"odtrans uploadFile",attrs:{"id":"odTransUploadFile","bucketName":"OD","program":"OD","parentObjectType":"ODTransaction","parentObjectId":item.id,"itemIsSaved":true,"showText":false}})],1):_vm._e()])]}},{key:"item.registration",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('td',{staticClass:"text-start clickable",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(_vm._s(value))])]}},{key:"item.register",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('td',{staticClass:"text-start clickable",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(_vm._s(value))])]}},{key:"item.finalized",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('td',{staticClass:"text-start clickable",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(_vm._s(value))])]}},{key:"item.departmentDetail.dept",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('td',{staticClass:"text-start clickable",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(_vm._s(value))])]}},{key:"item.departmentDetail.description",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('td',{staticClass:"text-start clickable",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(_vm._s(value))])]}},{key:"item.depositType",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('td',{staticClass:"text-start clickable",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(_vm._s(value))])]}},{key:"item.remarks",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('td',{staticClass:"text-start clickable",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(_vm._s(_vm.limitRemarkLength(value)))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('span',{staticClass:"pointer",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(_vm._s(_vm.amountFormat(value)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('td',{staticClass:"text-start clickable",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.isDepositAndOutstanding(item) ? "" : value)+" ")])]}},{key:"item.voucher",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('td',{staticClass:"text-start clickable",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(_vm._s(value))])]}},{key:"item.whomStr",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('td',{staticClass:"text-start clickable",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(_vm._s(value))])]}},{key:"item.whatStr",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('td',{staticClass:"text-start clickable",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(_vm._s(value))])]}},{key:"item.hasPrinted",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('td',{staticClass:"text-start clickable",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(value >= 1 ? "Yes" : "No")+" ")])]}},(_vm.selectedItemsAmount !== 0)?{key:"footer",fn:function(){return [_c('br'),_c('div',{staticClass:"table-footer-prepend",class:_vm.selectedItemsAmount < 0 ? 'red--text' : ''},[_vm._v(" "+_vm._s(("Total: " + (_vm.amountFormat(_vm.selectedItemsAmount))))+" ")])]},proxy:true}:null],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-dialog',{attrs:{"persistent":"","width":"35vw","height":"145vh"},model:{value:(_vm.cancelledDialog),callback:function ($$v) {_vm.cancelledDialog=$$v},expression:"cancelledDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Mark vouchers \"Cancelled By Statute?\"")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.cancelledByStatute(true)}}},[_vm._v(" Yes ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.cancelledByStatute(false)}}},[_vm._v(" No ")])],1)],1)],1)],1),_c('SummaryDialog',{attrs:{"sumOfTrans":_vm.sumOfTrans},model:{value:(_vm.sumOfTrans.isDisplay),callback:function ($$v) {_vm.$set(_vm.sumOfTrans, "isDisplay", $$v)},expression:"sumOfTrans.isDisplay"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }