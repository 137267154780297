<template>
  <v-data-table
    dense
    :headers="headers"
    :items="internalValue"
    :items-per-page="-1"
    hide-default-footer
    class="elevation-1 EFTDetails"
  >
    <template v-slot:top>
      <div class="d-flex justify-end">
        <v-btn
          class="mx-2" color="primary"
          fab icon x-small
          @click="addOneAndFocusIfFirst"
          v-if="amountToNumber(amountRemaining) !== 0 || internalValue.length === 0"
        >
          <v-icon>
            {{icons.mdiPlus}}
          </v-icon>
        </v-btn>
      </div>
    </template>

    <template v-slot:[`item.EFTType`]="{ item }">
      <v-select
        dense
        class="eft-type"
        ref="type"
        v-model="item.EFTType"
        :items="EFTTypes"
        :rules="eftTypeValidation"
        focus
        @change="somethingChanged"
        item-text="description"
        item-value="name"
      ></v-select>
    </template>
    <template v-slot:[`item.amount`]="{ item }">
      <v-text-field
        dense
        v-model="item.amount"
        :rules="amountValidation"
        single-line
        class="eft-amount"
        @keydown="shortcutHandler"
        @keydown.enter="somethingChanged"
        @blur="somethingChanged()"
      ></v-text-field>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small class="mr-2"
        tabindex="-1"
        @click="removeOne(item)"
      >
        {{icons.mdiDelete}}
      </v-icon>
    </template>

    <template slot="body.append">
      <tr class="blue--text">
          <td colspan="2" id="eft-remaining-amount">
            Total Remaining: ${{ amountFormat(amountRemaining) }}
          </td>
      </tr>
    </template>

  </v-data-table>
</template>

<script>
import {
  mdiPlus,
  mdiDelete,
} from '@mdi/js';

import { mapState } from 'vuex';

import {
  isTabOrPlus,
  focusBySelector,
  amountToNumber,
  amountFormat,
  selectInputTextBySelector,
} from '../../util/shared/vue-global';

const defaultItem = {
  EFTType: undefined,
  amount: 0,
};

export default {
  name: 'EFTDetails',
  components: {
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    componentKey: Number,
    depositAmount: [Number, String],
  },
  data: () => ({
    icons: {
      mdiPlus,
      mdiDelete,
    },
    headers: [
      { text: ' EFT Type', value: 'EFTType' },
      { text: 'Amount', value: 'amount' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
  }),
  computed: {
    ...mapState({
      EFTTypes: (state) => state.enums.ODEFTItemTypeEnum,
    }),
    internalValue: {
      get() {
        const newVal = JSON.parse(JSON.stringify(this.value));
        const updatedValue = newVal.map(this.loadInitialValues);
        return updatedValue;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    eftTypeValidation() {
      return [
        (value) => !!value || 'Required',
      ];
    },
    amountValidation() {
      return [
        (value) => !!value || 'Required',
        (value) => value !== 0 || 'Should be non-zero',
      ];
    },
    amountRemaining() {
      let totalRemainingAmount = amountToNumber(this.depositAmount);
      for (let i = 0; i < this.internalValue.length; i += 1) {
        totalRemainingAmount -= amountToNumber(this.internalValue[i].amount);
      }
      return totalRemainingAmount;
    },
  },
  watch: {
  },
  methods: {
    amountToNumber,
    amountFormat,
    loadInitialValues(item) {
      return {
        ...item,
        amount: amountFormat(amountToNumber(item.amount)),
      };
    },
    shortcutHandler(e) {
      if (isTabOrPlus(e)) {
        const allAmounts = document.querySelectorAll('.eft-amount input');
        const allLastColumns = [...allAmounts];
        if (allAmounts.length > 0) {
          if (allLastColumns[allLastColumns.length - 1].id === e.target.id) {
            let totalAmount = 0;
            allAmounts.forEach((v) => {
              totalAmount += amountToNumber(v.value);
              this.somethingChanged();
            });
            if (amountToNumber(this.depositAmount) !== totalAmount) {
              this.addOne();
              this.$nextTick(() => {
                focusBySelector('.eft-type input', 'last');
                selectInputTextBySelector('.eft-type input', 'last');
              });
            }
          }
        }
      }
    },
    addOneAndFocusIfFirst() {
      this.addOne();
      this.$nextTick(() => {
        const allAmounts = document.querySelectorAll('.eft-amount input');
        if (allAmounts.length === 1) {
          focusBySelector('.eft-type input', 'last');
          selectInputTextBySelector('.eft-type input', 'last');
        }
      });
    },
    addOne() {
      const allAmounts = document.querySelectorAll('.cash-check-amount input');
      const lastAmount = allAmounts[allAmounts.length - 1]
        ? allAmounts[allAmounts.length - 1].value : 0;
      this.$emit('input', [
        ...this.internalValue,
        {
          ...defaultItem,
          amount: amountFormat(amountToNumber(lastAmount)),
        },
      ]);
      return true;
    },
    removeOne(item) {
      this.$emit('input', [
        ...this.internalValue.filter((candidate) => item !== candidate),
      ]);
    },
    somethingChanged() {
      this.$emit('input', this.internalValue);
    },
  },
};
</script>

<style lang="sass">
  .eft-amount input
    text-align: right
</style>
