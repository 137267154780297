var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 TrustDetails",attrs:{"dense":"","headers":_vm.headers,"items":_vm.internalValue,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary","fab":"","icon":"","x-small":""},on:{"click":_vm.addOneAndFocusIfFirst}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1),(_vm.isVisibleForteBtn)?_c('FortePayment',{on:{"forteResponse":_vm.forteResponse}}):_vm._e()],1)]},proxy:true},{key:"item.taxId",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{ref:("item_" + (_vm.internalValue.indexOf(item))),staticClass:"trust-tax-id",attrs:{"dense":"","single-line":""},on:{"blur":_vm.somethingChanged,"keydown":function($event){return _vm.shortcutHandler($event, item)}},model:{value:(item.taxId),callback:function ($$v) {_vm.$set(item, "taxId", $$v)},expression:"item.taxId"}})]}},{key:"item.taxYear",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"dense":"","single-line":""},on:{"blur":_vm.somethingChanged,"keydown":function($event){return _vm.shortcutHandler($event, item)}},model:{value:(item.taxYear),callback:function ($$v) {_vm.$set(item, "taxYear", $$v)},expression:"item.taxYear"}})]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('v-textarea',{attrs:{"dense":"","single-line":"","rows":"1"},on:{"blur":_vm.somethingChanged,"keydown":function($event){return _vm.shortcutHandler($event, item)}},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})]}},{key:"item.paymentType",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"dense":"","items":_vm.paymentTypes,"item-text":"description","item-value":"name","single-line":""},on:{"blur":function($event){_vm.somethingChanged(); _vm.updateForteConfig()},"keydown":function($event){return _vm.shortcutHandler($event, item)}},model:{value:(item.paymentType),callback:function ($$v) {_vm.$set(item, "paymentType", $$v)},expression:"item.paymentType"}})]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-text-field',{staticClass:"trust-amount",attrs:{"dense":"","single-line":""},on:{"blur":function($event){_vm.somethingChanged(); _vm.sumAmount(); _vm.updateForteConfig()},"keydown":[function($event){return _vm.shortcutHandler($event, item, true, index)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.somethingChanged($event)}],"focus":_vm.focusAmount},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2 od-trust-delete-icon",attrs:{"small":"","tabindex":"-1"},on:{"click":function($event){return _vm.removeOne(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}},{key:"item.paid",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"mr-2 od-trust-ispaid-checkbox",attrs:{"small":""},on:{"change":_vm.somethingChanged},model:{value:(item.isPaid),callback:function ($$v) {_vm.$set(item, "isPaid", $$v)},expression:"item.isPaid"}})]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',{staticClass:"blue--text"},[_c('td',{attrs:{"colspan":"2","id":"total-trust-amount"}},[_vm._v(" Total : $"+_vm._s(_vm.amountFormat(_vm.amountRemaining))+" ")])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }