<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-text-field v-bind="attrs" v-on="on"
        v-model="voucherLocal"
        :rules="voucherValidation"
        :label="voucherLabel"
        :readonly="!voucherOverride && !isVoucherOdSetting"
        @dblclick.stop="overrideVoucherNumber"
        color="red"
        type="number"
        :class="voucherRegPulseClass"
      ></v-text-field>
    </template>
    <span>{{ voucherHoverCaption }}</span>
  </v-tooltip>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'VoucherNumberInput',
  data: () => ({
    voucherOverride: false,
  }),
  props: [
    'voucher',
    'department',
    'fiscalYear',
    'isNewItem',
  ],
  model: {
    prop: 'voucher',
    event: 'voucherchg',
  },
  created() {
    if (this.department && this.fiscalYear) {
      this.$store.dispatch('OD/loadVoucherStats', {
        department: this.department,
        fiscalYear: this.fiscalYear,
      });
    }
    this.$store.dispatch('SystemConfig/loadConfig', { requiredScope: 'odconfig' });
  },
  computed: {
    voucherValidation() {
      return [
        (value) => {
          if (value && value.toString().length > 10) {
            return 'Voucher length cannot be more than 10 digits';
          }
          return true;
        },
      ];
    },
    ...mapState({
      nextVoucherNumber: (state) => state.OD.nextVoucherNumber,
      voucherRegPulse: (state) => state.OD.voucherRegPulse,
      odSettingItem: (state) => state.SystemConfig.odSettingItem,
    }),
    voucherRegPulseClass() {
      return this.voucherRegPulse ? 'color-anim-pulse' : 'color-anim-black';
    },
    voucherLabel() {
      if (this.nextVoucherNumber === undefined) {
        return 'Voucher # (last: ...)';
      }
      return `Voucher # (last: ${this.nextVoucherNumber - 1})`;
    },
    voucherLocal: {
      get() {
        return this.voucher;
      },
      set(value) {
        this.$emit('voucherchg', value);
      },
    },
    isVoucherOdSetting() {
      return this.odSettingItem && this.odSettingItem.voucherNumber;
    },
    voucherHoverCaption() {
      if (!this.isNewItem || this.isVoucherOdSetting) {
        return 'Double-click to override (use ⚠️ caution)';
      }
      return 'Voucher must be saved before its number can be changed';
    },
  },
  methods: {
    ...mapMutations('OD', [
      'setVoucherRegPolling',
    ]),
    overrideVoucherNumber() {
      if (this.isVoucherOdSetting || !this.isNewItem) {
        this.voucherOverride = true;
        this.setVoucherRegPolling(undefined);
      }
    },
  },
};
</script>
